import React from 'react';
import { Box, Typography } from '@mui/material';

const Container = ({ header, children, showHeader = true }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        top: 0, // Ensure this is appropriate for your layout
        zIndex: 10, // Ensure it's above other elements
        // background: 'rgba(0, 0, 0, 0.8)', // Use a solid background color
        padding: '1rem', // Add some padding to ensure space around
        // marginTop: "5rem",
        color: "text.primary",
        textAlign: "left",
        overflow: 'visible', // Ensure overflow is visible
      }}
    >
      {showHeader  && header && (
        <>
        <Typography
        variant="h5"
        fontWeight="700"
        textTransform="uppercase"
        sx={{
          color: "white",
          paddingLeft: { xs: '10px', md: '20px' },
          paddingRight: { xs: '10px', md: '20px' },
          fontSize: { xs: '1.2rem', md: '1.5rem', lg: '2rem' },
        }}
      >
        <i>{header}</i>
      </Typography>
        <Box
          sx={{
            paddingX: { xs: 0, md: 0 },
            maxWidth: "1700px",
            width: "100%",
            display: 'grid',
            gridTemplateColumns: '1fr auto 1fr',
            alignItems: 'center',
            marginBottom: "-5vh", // Adjusted margin
          }}
        >

        </Box>
        </>
      )}
      <Box sx={{ overflow: 'visible' }}>
        {children}
      </Box>
    </Box>
  );
};

export default Container;
