import React, { useState, useEffect, useRef, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import { Button, Avatar, Box, Typography, CircularProgress, IconButton } from '@mui/material';
import PhotoCamera from '@mui/icons-material/PhotoCamera';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import 'bootstrap/dist/css/bootstrap.min.css';
import CustomPhotoCollage from '../Component/PhotoCollage';
// import Video from '../Component-4/Video';
import FollowIcon from '@mui/icons-material/PersonAdd';
import UnfollowIcon from '@mui/icons-material/PersonRemove';

export default function ProfilePage({ loggedInUser, onUpdateProfilePicture }) {
  const { userId } = useParams();
  const [user, setUser] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [preview, setPreview] = useState(null);
  const [posts, setPosts] = useState([]);
  const [uploading, setUploading] = useState(false);
  const [uploadSuccess, setUploadSuccess] = useState(false);
  const fileInputRef = useRef();
  const [isFollowing, setIsFollowing] = useState(false); // To track following status
  const userlocalId = JSON.parse(localStorage.getItem('user')).id; // Logged-in user's ID
  const token = localStorage.getItem('token');
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);

  
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/admin/users/${userId}`);
        setUser(response.data);
        // Check if the logged-in user is already following this cast
        const isUserFollowing = response.data.followers.includes(userlocalId);
        setIsFollowing(isUserFollowing);

        console.log(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchPosts = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/admin/posts/user/${userId}`);
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching posts:', error);
      }
    };

    fetchUserData();
    fetchPosts();
  }, [userId, userlocalId, token]);


  // Define fetchFollowersAndFollowing using useCallback
  const fetchFollowersAndFollowing = useCallback(async () => {
    try {
      const [followersRes, followingRes] = await Promise.all([
        axios.get(`${apiBaseURL}/api/users/${userId}/followers`, {
          headers: { 'x-auth-token': token },
        }),
        axios.get(`${apiBaseURL}/api/users/${userId}/following`, {
          headers: { 'x-auth-token': token },
        }),
      ]);

      setFollowers(followersRes.data.followers);
      setFollowing(followingRes.data.following);
    } catch (err) {
      console.error("Error fetching followers/following:", err);
    }
  }, [userId, token]);

  useEffect(() => {
    fetchFollowersAndFollowing();
  }, [fetchFollowersAndFollowing]);

  // Handle follow button click
  const handleFollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userlocalId}/follow`,
        { targetUserId: userId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(true); // Update UI after successful follow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error following user:", err);
    }
  };

  // Handle unfollow button click
  const handleUnfollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userlocalId}/unfollow`,
        { targetUserId: userId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(false); // Update UI after successful unfollow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error unfollowing user:", err);
    }
  };


  const handleFileChange = (e) => {
    const file = e.target.files[0];
    setSelectedFile(file);
    setPreview(URL.createObjectURL(file));
    setUploadSuccess(false);
  };

  const handleProfilePictureUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('profilePicture', selectedFile);
      try {
        setUploading(true);
        const response = await axios.put(`${apiBaseURL}/api/admin/superuser/profile-picture`, formData, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          }
        });
        setUser({ ...user, profilePicture: response.data.profilePicture });
        onUpdateProfilePicture(response.data.profilePicture);
        setUploading(false);
        setUploadSuccess(true);
        setPreview(null);
      } catch (error) {
        console.error('Error updating profile picture:', error);
        setUploading(false);
      }
    } else {
      console.error('No file selected');
    }
  };

  const isCurrentUser = loggedInUser?.id?.toString() === user?._id?.toString();
  const isSuperUser = user?.role === 'super-user';
  const showUploadButton = isCurrentUser && isSuperUser;

  const photos = posts.map(post => ({ source: `${apiBaseURL}/uploads/${post.mediaPath}` }));

  return (
    <Box sx={{ padding: 2, background: "linear-gradient(to right, #15171a, #15171a, #15171a)", borderRadius: 2, boxShadow: 2 }}>
      <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 2, position: 'relative' }}>
        <Box sx={{ position: 'relative', cursor: 'pointer' }} onClick={() => fileInputRef.current.click()}>
          <Avatar
            src={preview || `${apiBaseURL}/uploads/${user?.profilePicture}` || "https://mdbcdn.b-cdn.net/img/Photos/new-templates/bootstrap-chat/ava3.webp"}
            sx={{ width: 80, height: 80 }}
          />
          {showUploadButton && (
            <>
              <input
                type="file"
                onChange={handleFileChange}
                style={{ display: 'none' }}
                ref={fileInputRef}
              />
              {!preview && (
                <Box sx={{
                  position: 'absolute',
                  top: 0,
                  right: 0,
                  bottom: 0,
                  left: 0,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: 'rgba(0, 0, 0, 0.5)',
                  color: 'white',
                  borderRadius: '50%',
                  opacity: 0,
                  transition: 'opacity 0.3s',
                  '&:hover': {
                    opacity: 1,
                  },
                }}>
                  <PhotoCamera />
                </Box>
              )}
            </>
          )}
        </Box>
        <Box sx={{ marginLeft: 2 }}>
          {/* Flex container to align user details and button */}
          <Box sx={{ display: 'flex', alignItems: 'center', marginBottom: 1 }}>
            {/* User details */}
            <Box sx={{ flexGrow: 1 }}>
              <Typography variant="h6" style={{ color: "#D3D3D3" }}>
                {user?.firstName + ' ' + user?.lastName}
              </Typography>
              <Typography variant="body2" color="textSecondary" style={{ color: "#D3D3D3" }}>
                {user?.contactInfo.address.city || "Bay Area, San Francisco, CA"}
              </Typography>
            </Box>

            {/* Follow/Unfollow Button */}
            {/* Follow/Unfollow Icon */}
    <IconButton
      onClick={isFollowing ? handleUnfollow : handleFollow}
      sx={{ marginLeft: 2, color: isFollowing ? 'error.main' : 'primary.main' }}
    >
      {isFollowing ? <UnfollowIcon /> : <FollowIcon />}
    </IconButton>
          </Box>

          {/* Followers and Following Count */}
          <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey', fontSize: '1rem' }}>
            Followers: {followers.length}
            <span style={{ margin: '1vw', color: 'grey', fontSize: '1rem' }}>
              Following: {following.length}
            </span>
          </Typography>
          {showUploadButton && preview && (
            <Box sx={{ display: 'flex', alignItems: 'center', marginTop: 1 }}>
              <Button
                onClick={handleProfilePictureUpload}
                variant="contained"
                sx={{ marginRight: 1 }}
                disabled={uploading}
              >
                {uploading ? <CircularProgress size={24} /> : 'Confirm'}
              </Button>
              <Button
                onClick={() => setPreview(null)}
                variant="outlined"
              >
                Cancel
              </Button>
              {uploadSuccess && (
                <CheckCircleOutlineIcon color="success" sx={{ marginLeft: 1 }} />
              )}
            </Box>
          )}
        </Box>
      </Box>
      <Box sx={{ textAlign: 'center', marginBottom: 2 }}>
        {photos.length > 0 ? (
          <CustomPhotoCollage photos={photos} />
        ) : (
          <Typography style={{color:"#D3D3D3"}}>No posts to display</Typography>
        )}
      </Box>
      {/* <Box>
        <Video style={{ width: '100%' }} />
      </Box> */}
    </Box>
  );
}
