import axios from 'axios';

const addFavorite = async (userId, mediaId) => {
  try {
    const response = await axios.post(`http://thecameratales.com:5000/api/movie/${userId}/favorites/${mediaId}`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

const removeFavorite = async (userId, mediaId) => {
  try {
    const response = await axios.delete(`http://thecameratales.com:5000/api/movie/${userId}/favorites/${mediaId}`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

const getFavoriteStatus = async (userId, mediaId) => {
  try {
    const response = await axios.get(`http://thecameratales.com:5000/api/movie/${userId}/favorites/${mediaId}`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

const getAllFavorites = async (userId) => {
  try {
    const response = await axios.get(`http://thecameratales.com:5000/api/users/${userId}/favorites`);
    return { response: response.data, err: null };
  } catch (err) {
    return { response: null, err };
  }
};

export { addFavorite, removeFavorite, getFavoriteStatus, getAllFavorites };
