import { Box, Button, Chip, Divider, Grid, Stack, Typography, useTheme } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import { setGlobalLoading } from "../../redux/features/globalLoadingSlice";
import { routesGen } from "../../routes/routes";

import uiConfigs from "../../configs/ui.configs";

import CircularRate from "./CircularRate";

import tmdbConfigs from "../../api/configs/tmdb.configs";
// import genreApi from "../../api/modules/genre.api";
import mediaApi from "../../api/modules/media.api";

const HeroSlide = ({ mediaType, mediaCategory }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [movies, setMovies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        dispatch(setGlobalLoading(true));
        console.log('Fetching movies for mediaType:', mediaType, 'and mediaCategory:', mediaCategory);
        const { response: mediaResponse, err: mediaError } = await mediaApi.getList({ mediaType, mediaCategory, page: 1 });
        if (mediaError) throw new Error(mediaError.message);
        console.log('Fetched movies:', mediaResponse.data);
        setMovies(mediaResponse.data);
      } catch (error) {
        toast.error(error.message);
      } finally {
        dispatch(setGlobalLoading(false));
      }
    };

    fetchData();
  }, [mediaType, mediaCategory, dispatch]);
  console.log('Rendered movies:', movies);

  return (
    <Box sx={{ position: "relative", color: "primary.contrastText", marginTop:"5vh" }}>
      <Swiper grabCursor={true} loop={true} style={{ width: "100%", height: "max-content" }}>
        {movies.map((movie, index) => (
          <SwiperSlide key={index}>
            <Box sx={{ position: "relative" }}>
              <Box
                sx={{
                  position: "absolute",
                  top: 0,
                  left: 0,
                  height: "100%",
                  backgroundColor: "rgba(0, 0, 0, 0.5)", // Adjust opacity as needed
                  zIndex: 3,
                  pointerEvents: "none" // Prevent overlay from intercepting touch events
                }}
              />
              <Box
                sx={{
                  paddingTop: { xs: "130%", sm: "80%", md: "60%", lg: "45%" },
                  backgroundPosition: "top",
                  backgroundSize: "cover",
                  backgroundImage: `url(${tmdbConfigs.backdropPath(movie.backdrop_path || movie.poster_path)})`,
                  zIndex: 2 // Ensure image is rendered above the overlay
                }}
              />
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  position: "absolute",
                  top: 0,
                  left: 0,
                  paddingX: { xs: "10px", sm: "20px", md: "5rem", lg: "10rem" }, // Adjust padding for smaller screens
                  zIndex: 6, // Ensure container is rendered above the overlay
                  ...uiConfigs.style.horizontalGradientBgImage[theme.palette.mode],
                  display: "flex", // Add display flex to center the Grid
                  alignItems: "center" // Vertically center the Grid
                }}
              >
                <Grid container spacing={4} alignItems="center">
                  <Grid item xs={12} sm={8} md={6} lg={4}>
                    <Stack spacing={4} direction="column">
                      <Typography
                        variant="h4"
                        fontSize={{ xs: "1.5rem", sm: "2rem", md: "2rem", lg: "4rem" }}
                        fontWeight="700"
                        sx={{
                          ...uiConfigs.style.typoLines(2, "left"),
                          position: "relative", // Ensure Typography is rendered above the overlay
                          zIndex: 6, // Ensure Typography is rendered above the overlay
                          color: "transparent",
                          backgroundImage: "linear-gradient(to right, #F7971E 0%, #FFD200  51%, #F7971E  100%)",
                          WebkitBackgroundClip: "text"
                        }}
                      >
                        {movie.title || movie.name}
                      </Typography>
                      <Stack direction="row" spacing={1} alignItems="center">
                        <CircularRate value={movie.vote_average} />
                        <Divider orientation="vertical" />
                        {movie.genre && movie.genre.map((genre, index) => (
                          <Chip
                            key={index}
                            label={genre}
                            sx={{ backgroundColor: "purple", backgroundImage: "linear-gradient(to right, #780206, #061161)", marginRight: '5px', fontWeight: 'bolder', color: 'white' }}
                          />
                        ))}
                      </Stack>
                      <Stack spacing={4} direction="column">
                        <Typography
                          variant="body1"
                          sx={{
                            ...uiConfigs.style.typoLines(3),
                            color: "white",
                            position: "relative", // Ensure Typography is rendered above the overlay
                            zIndex: 6, // Ensure Typography is rendered above the overlay
                            maxWidth: "80%", // Ensure text does not overflow on smaller screens
                            marginRight: { xs: 0, sm: "20px", md: "30px" }
                          }}
                        >
                          <b><i>{movie.overview}</i></b>
                        </Typography>
                      </Stack>
                      <Button
                        variant="contained"
                        size="large"
                        startIcon={<PlayArrowIcon />}
                        component={Link}
                        to={routesGen.mediaDetail(mediaType, movie._id)}
                        sx={{
                          width: "max-content",
                          backgroundColor: "purple",
                          borderRadius: "50px",
                          zIndex: 6,
                          backgroundImage: "linear-gradient(to right, #780206, #061161)",
                          marginTop: { xs: "10px", sm: 0 } // Adjust margin top for smaller screens
                        }}
                      >
                        watch now
                      </Button>
                    </Stack>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  );
};

export default HeroSlide;

