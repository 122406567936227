const mediaType = {
  movie: "movie",
  tv: "tv",
  webseries: "webseries",
  webshows: "webshows",
  shows: "shows",
  musicvideos: "musicvideos",
  toptalesthisweek: "toptalesthisweek",
  shortfilms: "shortfilms",
  tales: "tales",
  upcoming: "upcoming",
  fridaytalkies: "firdaytalkies",
  favorites:"favorites",
  viewed:"viewed",
};

const mediaCategory = {
  popular: "popular",
  top_rated: "top_rated"
};

const backdropPath = (imgEndpoint) => `http://thecameratales.com:5000/uploads/${imgEndpoint}`;

const posterPath = (imgEndpoint) => {
  // Check if the imgEndpoint already starts with 'https://backend.thecameratales.com/uploads/', if so, return it as it is
  if (imgEndpoint.startsWith('http://thecameratales.com:5000/uploads/')) {
    return imgEndpoint;
  }
  // Otherwise, append 'https://backend.thecameratales.com/uploads/' before the imgEndpoint
  return `http://thecameratales.com:5000/uploads/${imgEndpoint}`;
};


const youtubePath = (videoId) => `https://www.youtube.com/embed/${videoId}?controls=0`;

const tmdbConfigs = {
  mediaType,
  mediaCategory,
  backdropPath,
  posterPath,
  youtubePath
};

export default tmdbConfigs;
