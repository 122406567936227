import React, { useState, useEffect } from 'react';
import axios from 'axios';
import CmsNavbar from './CmsNavbar';
// import { color, minWidth } from '@mui/system';

const CMSMediaForm = () => {
    const [formData, setFormData] = useState({
        title: '',
        overview: '',
        genre: '',
        release_date: '',
        adult: false,
        original_language: '',
        popularity: 5,
        vote_average: 5,
        vote_count: 5,
        type: 'movie', // default type
        video: false,
        poster_path: null,
        backdrop_path: null,
        video_url: [],
        videoThumbnailUrl: [], // Add video thumbnail here
        userId: '', // Add userId here
        producers: [],
        directors: [],
        writers: [],
        coproducers: [],
        execuetiveproducers: [],
        actors: [],
        productionmanagers: [],
        assistantdirectors: [],
        associatedirectors: [],
        actiondirectors: [],
        ticketPrice: '' // Optional field for ticket price
        // Continue with other dynamic fields (actors, assistant directors, etc.)
    });

    const [uploadProgress, setUploadProgress] = useState(0); // State to track upload progress
    const [isSubmitting, setIsSubmitting] = useState(false); // State to disable form during upload
    const [showModal, setShowModal] = useState(false); // State to control modal visibility
    const [logMessages, setLogMessages] = useState([]);

    // Retrieve the userId from local storage when the component mounts
    useEffect(() => {
        const userData = JSON.parse(localStorage.getItem('user'));
        if (userData && userData.id) {
            setFormData((prevData) => ({ ...prevData, userId: userData.id }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value,
        });
    };

    // Add function to dynamically add more producers, directors, etc.
    const addProducer = () => {
        setFormData({ ...formData, producers: [...formData.producers, { email: '', profile_picture: null }] });
    };

    const removeProducer = (index) => {
        const updatedProducers = formData.producers.filter((_, i) => i !== index);
        setFormData({ ...formData, producers: updatedProducers });
    };

    // directorrs

    // Add function to dynamically add more producers, directors, etc.
    const addDirector = () => {
        setFormData({ ...formData, directors: [...formData.producers, { email: '', profile_picture: null }] });
    };

    const removeDirector = (index) => {
        const updatedDirectors = formData.directors.filter((_, i) => i !== index);
        setFormData({ ...formData, directors: updatedDirectors });
    };

    // writers

    // directorrs

    // Add function to dynamically add more producers, directors, etc.
    const addWriter = () => {
        setFormData({ ...formData, writers: [...formData.writers, { email: '', profile_picture: null }] });
    };

    const removeWriter = (index) => {
        const updatedWriters = formData.writers.filter((_, i) => i !== index);
        setFormData({ ...formData, writers: updatedWriters });
    };



    // Co-Producer

    // Add function to dynamically add more producers, directors, etc.
    const addCoproducer = () => {
        setFormData({ ...formData, coproducers: [...formData.coproducers, { email: '', profile_picture: null }] });
    };

    const removeCoproducer = (index) => {
        const updatedCoproducers = formData.coproducers.filter((_, i) => i !== index);
        setFormData({ ...formData, coproducers: updatedCoproducers });
    };

    // Execuetive-Producer

    // Add function to dynamically add more producers, directors, etc.
    const addExecuetiveproducer = () => {
        setFormData({ ...formData, execuetiveproducers: [...formData.execuetiveproducers, { email: '', profile_picture: null }] });
    };

    const removeExecuetiveproducer = (index) => {
        const updatedExecuetiveproducers = formData.execuetiveproducers.filter((_, i) => i !== index);
        setFormData({ ...formData, execuetiveproducers: updatedExecuetiveproducers });
    };

    // Actors

    // Add function to dynamically add more producers, directors, etc.
    const addActor = () => {
        setFormData({ ...formData, actors: [...formData.actors, { email: '', profile_picture: null }] });
    };

    const removeActor = (index) => {
        const updatedActors = formData.actors.filter((_, i) => i !== index);
        setFormData({ ...formData, actors: updatedActors });
    };

    // Production-Managers

    // Add function to dynamically add more producers, directors, etc.
    const addProductionmanager = () => {
        setFormData({ ...formData, productionmanagers: [...formData.productionmanagers, { email: '', profile_picture: null }] });
    };

    const removeProductionmanager = (index) => {
        const updatedProductionmanagers = formData.productionmanagers.filter((_, i) => i !== index);
        setFormData({ ...formData, productionmanagers: updatedProductionmanagers });
    };


    // Assistant-Directors

    // Add function to dynamically add more producers, directors, etc.
    const addAssistantdirector = () => {
        setFormData({ ...formData, assistantdirectors: [...formData.assistantdirectors, { email: '', profile_picture: null }] });
    };

    const removeAssistantdirector = (index) => {
        const updatedAssistantdirectors = formData.assistantdirectors.filter((_, i) => i !== index);
        setFormData({ ...formData, assistantdirectors: updatedAssistantdirectors });
    };


    // Associate-Directors

    // Add function to dynamically add more producers, directors, etc.
    const addAssociatedirector = () => {
        setFormData({ ...formData, associatedirectors: [...formData.associatedirectors, { email: '', profile_picture: null }] });
    };

    const removeAssociatedirector = (index) => {
        const updatedAssociatedirectors = formData.associatedirectors.filter((_, i) => i !== index);
        setFormData({ ...formData, associatedirectors: updatedAssociatedirectors });
    };

    // Action-Directors

    // Add function to dynamically add more producers, directors, etc.
    const addActiondirector = () => {
        setFormData({ ...formData, actiondirectors: [...formData.actiondirectors, { email: '', profile_picture: null }] });
    };

    const removeActiondirector = (index) => {
        const updatedActiondirectors = formData.actiondirectors.filter((_, i) => i !== index);
        setFormData({ ...formData, actiondirectors: updatedActiondirectors });
    };



    // Function to handle dynamic field updates like adding producers or directors
    const handleDynamicFieldChange = (e, index, field) => {
        const updatedFields = [...formData[field]];
        updatedFields[index] = {
            ...updatedFields[index],
            [e.target.name]: e.target.value,
        };
        setFormData({ ...formData, [field]: updatedFields });
    };

    const handleFileChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.files[0], // For file uploads
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsSubmitting(true); // Disable form inputs
        setUploadProgress(0); // Reset upload progress

        const form = new FormData();
        form.append('title', formData.title);
        form.append('overview', formData.overview);
        form.append('genre', formData.genre);
        form.append('release_date', formData.release_date);
        form.append('adult', formData.adult);
        form.append('original_language', formData.original_language);
        form.append('popularity', formData.popularity);
        form.append('vote_average', formData.vote_average);
        form.append('vote_count', formData.vote_count);
        form.append('type', formData.type);
        form.append('video', formData.video);

        // Append thumbnail file if it exists
        // Append thumbnail file if it exists
        if (formData.videoThumbnailUrl) {
            form.append('videoThumbnailUrl', formData.videoThumbnailUrl);
        }

        // Only append ticketPrice if it's not empty
        if (formData.ticketPrice) {
            form.append('ticketPrice', formData.ticketPrice);
        }

        // Append producers, directors, etc.
        formData.producers.forEach((producer, index) => {
            formData.append(`producers[${index}].email`, producer.email);
            formData.append(`producer_profile_picture_${index}`, producer.profile_picture);
        });

        // Director
        formData.directors.forEach((director, index) => {
            formData.append(`directors[${index}].email`, director.email);
            formData.append(`director_profile_picture_${index}`, director.profile_picture);
        });

        //  Writer
        formData.writers.forEach((writers, index) => {
            formData.append(`writers[${index}].email`, writers.email);
            formData.append(`writers_profile_picture_${index}`, writers.profile_picture);
        });

        // Coproducer

        formData.coproducers.forEach((coproducers, index) => {
            formData.append(`coproducers[${index}].email`, coproducers.email);
            formData.append(`coproducers_profile_picture_${index}`, coproducers.profile_picture);
        });

        // Execuetive-Producers

        formData.execuetiveproducers.forEach((execuetiveproducers, index) => {
            formData.append(`execuetiveproducers[${index}].email`, execuetiveproducers.email);
            formData.append(`execuetiveproducers_profile_picture_${index}`, execuetiveproducers.profile_picture);
        });

        // Actors

        formData.actors.forEach((actors, index) => {
            formData.append(`actors[${index}].email`, actors.email);
            formData.append(`actors_profile_picture_${index}`, actors.profile_picture);
        });

        // Production-mamagers

        formData.productionmanagers.forEach((productionmanagers, index) => {
            formData.append(`productionmanagers[${index}].email`, productionmanagers.email);
            formData.append(`productionmanagers_profile_picture_${index}`, productionmanagers.profile_picture);
        });

        // Assistant-Directors

        formData.assistantdirectors.forEach((assistantdirectors, index) => {
            formData.append(`assistantdirectors[${index}].email`, assistantdirectors.email);
            formData.append(`assistantdirectors_profile_picture_${index}`, assistantdirectors.profile_picture);
        });

        // Associate-Directors

        formData.associatedirectors.forEach((associatedirectors, index) => {
            formData.append(`associatedirectors[${index}].email`, associatedirectors.email);
            formData.append(`associatedirectors_profile_picture_${index}`, associatedirectors.profile_picture);
        });

        // Action-Directors

        formData.actiondirectors.forEach((actiondirectors, index) => {
            formData.append(`actiondirectors[${index}].email`, actiondirectors.email);
            formData.append(`actiondirectors_profile_picture_${index}`, actiondirectors.profile_picture);
        });



        // Include userId
        form.append('userId', formData.userId);

        if (formData.poster_path) {
            form.append('poster_path', formData.poster_path);
        }

        if (formData.backdrop_path) {
            form.append('backdrop_path', formData.backdrop_path);
        }

        if (formData.video_url) {
            form.append('video_url', formData.video_url);
        }

        const endpoint = `http://thecameratales.com:5000/api/${formData.type}`;

        try {
            const response = await axios.post(endpoint, form, {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
                onUploadProgress: (progressEvent) => {
                    const totalLength = progressEvent.lengthComputable
                        ? progressEvent.total
                        : progressEvent.target.getResponseHeader('content-length') ||
                        progressEvent.target.getResponseHeader('x-decompressed-content-length');

                    if (totalLength !== null) {
                        const percentage = Math.round((progressEvent.loaded * 100) / totalLength);
                        setUploadProgress(percentage);

                        setLogMessages(prevLogs => [
                            ...prevLogs,
                            `Upload Progress: ${percentage}%`
                        ]);
                    }
                },
            });

            // Adding more logs during encoding process (simulated for now)
            setLogMessages(prevLogs => [
                ...prevLogs,
                'Video encoding started...',
                'Encoding progress: 10%',
                'Encoding progress: 50%',
                'Encoding progress: 90%',
                'Encoding completed.',
                'Saving movie data...'
            ]);

            console.log('Success:', response.data);

            // Log success message
            setLogMessages(prevLogs => [
                ...prevLogs,
                'Movie uploaded and processed successfully!'
            ]);

            setUploadProgress(0); // Reset progress bar

            // Reset the form after successful submission
            setFormData({
                title: '',
                overview: '',
                genre: '',
                release_date: '',
                adult: false,
                original_language: '',
                popularity: 5,
                vote_average: 5,
                vote_count: 5,
                type: 'movie', // default type
                video: false,
                poster_path: null,
                backdrop_path: null,
                video_url: [],
                // userId: '', // Add userId here
                producers: [],
                directors: [],
                writers: [],
                coproducers: [],
                execuetiveproducers: [],
                actors: [],
                productionmanagers: [],
                assistantdirectors: [],
                associatedirectors: [],
                actiondirectors: [],
                userId: formData.userId, // Keep userId after reset
            });

            // Show thank you modal
            setShowModal(true);
        } catch (error) {
            console.error('Error posting data:', error.response?.data || error);
        } finally {
            setIsSubmitting(false); // Re-enable form inputs
            setUploadProgress(0); // Reset upload progress
        }
    };

    return (
        <>
            <CmsNavbar />
            <div style={styles.container}>
                <form onSubmit={handleSubmit} style={styles.form}>
                    <h2 style={styles.header}>Upload Media</h2>

                    <div style={styles.formGrid}>
                        <div style={styles.formGroup}>
                            <label style={styles.label}>Title</label>
                            <input
                                type="text"
                                name="title"
                                value={formData.title}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Genre</label>
                            <input
                                type="text"
                                placeholder='comma seperated'
                                name="genre"
                                value={formData.genre}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>



                        <div style={styles.formGroup}>
                            <label style={styles.label}>Release Date</label>
                            <input
                                type="date"
                                name="release_date"
                                value={formData.release_date}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Original Language</label>
                            <input
                                type="text"
                                name="original_language"
                                value={formData.original_language}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div>

                        {/* <div style={styles.formGroup}>
                            <label style={styles.label}>Popularity</label>
                            <input
                                type="number"
                                name="popularity"
                                value={formData.popularity}
                                onChange={handleChange}
                                style={styles.input}
                                required
                            />
                        </div> */}

                        {/* <div style={styles.formGroup}>
                            <label style={styles.label}>Vote Average</label>
                            <input
                                type="number"
                                step="0.1"
                                name="vote_average"
                                value={formData.vote_average}
                                onChange={handleChange}
                                style={styles.input}
                            />
                        </div> */}

                        {/* <div style={styles.formGroup}>
                            <label style={styles.label}>Vote Count</label>
                            <input
                                type="number"
                                name="vote_count"
                                value={formData.vote_count}
                                onChange={handleChange}
                                style={styles.input}
                            />
                        </div> */}

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Type</label>
                            <select
                                name="type"
                                value={formData.type}
                                onChange={handleChange}
                                style={styles.select}
                            >
                                <option value="movie">Movie</option>
                                <option value="webseries">Web Series</option>
                                <option value="shortfilms">Short Films</option>
                                <option value="upcoming">Upcoming</option>
                                <option value="webshows">Webshows</option>
                                <option value="tales">Tales</option>
                                <option value="toptalesthisweek">Top Tales This Week</option>
                                <option value="musicvideos">Music Videos</option>
                                <option value="shows">Shows</option>
                                <option value="fridaytalkies">Friday Talkies</option>
                            </select>
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Poster</label>
                            <input
                                type="file"
                                name="poster_path"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={styles.fileInput}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Video Thumbnail</label>
                            <input
                                type="file"
                                name="videoThumbnailUrl"
                                accept="video/*"  // Accept only video files for the thumbnail
                                onChange={handleFileChange}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Backdrop</label>
                            <input
                                type="file"
                                name="backdrop_path"
                                accept="image/*"
                                onChange={handleFileChange}
                                style={styles.fileInput}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Video File</label>
                            <input
                                type="file"
                                name="video_url"
                                accept="video/*"
                                onChange={handleFileChange}
                                style={styles.fileInput}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Log Line</label>
                            {/* maximum letters */}
                            <textarea
                                name="overview"
                                value={formData.overview}
                                onChange={handleChange}
                                style={styles.textarea}
                                required
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>
                                Adult Content
                            </label>
                            <input
                                type="checkbox"
                                name="adult"
                                checked={formData.adult}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Video</label>
                            <input
                                type="checkbox"
                                name="video"
                                checked={formData.video}
                                onChange={handleChange}
                            />
                        </div>

                        <div style={styles.formGroup}>
                            <label style={styles.label}>Ticket Price (For Friday Talkies)</label>
                            <input
                                type="number" // number field for ticket price
                                name="ticketPrice"
                                value={formData.ticketPrice}
                                onChange={handleChange}
                                style={styles.input}
                                placeholder="Enter ticket price"
                            />
                        </div>
                    </div>

                    {/* Add fields for producers */}
                    <div>
                        <label>Producers:</label>
                        {formData.producers.map((producer, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Producer email"
                                    value={producer.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'producers')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`producer_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedProducers = [...formData.producers];
                                        updatedProducers[index].profile_picture = file;
                                        setFormData({ ...formData, producers: updatedProducers });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeProducer(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addProducer}>
                            Add Producer
                        </button>
                    </div>

                    {/* Add similar sections for directors, writers, etc. */}


                    {/* Add fields for directors */}
                    <div>
                        <label>Directors:</label>
                        {formData.directors.map((director, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Director email"
                                    value={director.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'directors')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`director_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedDirectors = [...formData.directors];
                                        updatedDirectors[index].profile_picture = file;
                                        setFormData({ ...formData, directors: updatedDirectors });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeDirector(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addDirector}>
                            Add Director
                        </button>
                    </div>

                    {/* Add similar sections for directors, writers, etc. */}

                    {/* Writers */}

                    {/* Add fields for producers */}
                    <div>
                        <label>Writers:</label>
                        {formData.writers.map((writers, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Writers' email"
                                    value={writers.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'writers')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`writers_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedWriters = [...formData.writers];
                                        updatedWriters[index].profile_picture = file;
                                        setFormData({ ...formData, writers: updatedWriters });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeWriter(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addWriter}>
                            Add Writer
                        </button>
                    </div>

                    {/* Coproducer */}

                    {/* Add fields for producers */}
                    <div>
                        <label>Co-Producers:</label>
                        {formData.coproducers.map((coproducers, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Co-oproducer's email"
                                    value={coproducers.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'coproducers')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`coproducers_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedCoproducers = [...formData.coproducers];
                                        updatedCoproducers[index].profile_picture = file;
                                        setFormData({ ...formData, coproducers: updatedCoproducers });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeCoproducer(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addCoproducer}>
                            Add Co-Producer
                        </button>
                    </div>

                    {/* Execuetive-Producers */}


                    {/* Add fields for producers */}
                    <div>
                        <label>Execuetive-Producers:</label>
                        {formData.execuetiveproducers.map((execuetiveproducers, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Execuetive-producer's email"
                                    value={execuetiveproducers.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'execuetiveproducers')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`execuetiveproducers_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedExecuetiveproducers = [...formData.execuetiveproducers];
                                        updatedExecuetiveproducers[index].profile_picture = file;
                                        setFormData({ ...formData, execuetiveproducers: updatedExecuetiveproducers });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeExecuetiveproducer(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addExecuetiveproducer}>
                            Add Execuetive-Producer
                        </button>
                    </div>


                    {/* Actors */}

                    {/* Add fields for producers */}
                    <div>
                        <label>Casts:</label>
                        {formData.actors.map((actors, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Cast's email"
                                    value={actors.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'actors')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`actors_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedActors = [...formData.actors];
                                        updatedActors[index].profile_picture = file;
                                        setFormData({ ...formData, actors: updatedActors });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeActor(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addActor}>
                            Add Casts
                        </button>
                    </div>


                    {/* Production-Managers */}

                    {/* Add fields for producers */}
                    <div>
                        <label>Production-Managers:</label>
                        {formData.productionmanagers.map((productionmanagers, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Production-Manager's email"
                                    value={productionmanagers.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'productionmanagers')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`productionmanagers_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedProductionmanagers = [...formData.productionmanagers];
                                        updatedProductionmanagers[index].profile_picture = file;
                                        setFormData({ ...formData, productionmanagers: updatedProductionmanagers });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeProductionmanager(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addProductionmanager}>
                            Add Production-Managers
                        </button>
                    </div>


                    {/* Assistant-Directors */}


                    {/* Add fields for producers */}
                    <div>
                        <label>Assistant-Directors:</label>
                        {formData.assistantdirectors.map((assistantdirectors, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Assistant-Directors's email"
                                    value={assistantdirectors.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'assistantdirectors')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`assistantdirectors_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedAssistantdirectors = [...formData.assistantdirectors];
                                        updatedAssistantdirectors[index].profile_picture = file;
                                        setFormData({ ...formData, assistantdirectors: updatedAssistantdirectors });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeAssistantdirector(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addAssistantdirector}>
                            Add Assistant-Director
                        </button>
                    </div>


                    {/* Associate-Directors */}

                    {/* Add fields for producers */}
                    <div>
                        <label>Associate-Directors:</label>
                        {formData.associatedirectors.map((associatedirectors, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Associate-Directors's email"
                                    value={associatedirectors.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'associatedirectors')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`associatedirectors_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedAssociatedirectors = [...formData.associatedirectors];
                                        updatedAssociatedirectors[index].profile_picture = file;
                                        setFormData({ ...formData, associatedirectors: updatedAssociatedirectors });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeAssociatedirector(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addAssociatedirector}>
                            Add Associate-Director
                        </button>
                    </div>


                    {/* Action- Directors */}

                    {/* Add fields for producers */}
                    <div>
                        <label>Action-Directors:</label>
                        {formData.actiondirectors.map((actiondirectors, index) => (
                            <div key={index} style={{ marginBottom: '10px', border: '1px solid #ddd', padding: '10px' }}>
                                <input
                                    type="email"
                                    name="email"
                                    placeholder="Action-Directors's email"
                                    value={actiondirectors.email}
                                    onChange={(e) => handleDynamicFieldChange(e, index, 'actiondirectors')}
                                />
                                <input
                                    style={{ marginTop: '2vh' }}
                                    type="file"
                                    name={`actiondirectors_profile_picture_${index}`}
                                    onChange={(e) => handleFileChange(e, (file) => {
                                        const updatedActiondirectors = [...formData.actiondirectors];
                                        updatedActiondirectors[index].profile_picture = file;
                                        setFormData({ ...formData, actiondirectors: updatedActiondirectors });
                                    })}
                                />
                                {/* Remove button */}
                                <button type="button" onClick={() => removeActiondirector(index)} style={{ backgroundColor: 'red', color: 'white', margin: '10px', width: '5vw' }}>
                                    Remove
                                </button>
                            </div>
                        ))}
                        <button type="button" style={{ backgroundColor: 'black', color: 'white', margin: '10px' }} onClick={addActiondirector}>
                            Add Action-Director
                        </button>
                    </div>






                    <button type="submit" style={styles.submitButton} disabled={isSubmitting}>
                        {isSubmitting ? 'Submitting...' : 'Submit'}
                    </button>
                </form>

                {uploadProgress > 0 && (
                    <div style={styles.progressContainer}>
                        <progress value={uploadProgress} max="100" style={styles.progress} />
                        <p>{uploadProgress}%</p>
                    </div>
                )}

                {/* {logMessages.length > 0 && (
                    <div style={styles.logContainer}>
                        <h3>Process Log</h3>
                        <ul style={styles.logList}>
                            {logMessages.map((log, index) => (
                                <li key={index}>{log}</li>
                            ))}
                        </ul>
                    </div>
                )} */}

                {showModal && (
                    <div style={styles.modal}>
                        <div style={styles.modalContent}>
                            <h2>Thank you!</h2>
                            <p>Your media has been uploaded successfully.</p>
                            <button onClick={() => setShowModal(false)} style={styles.modalButton}>
                                Close
                            </button>
                        </div>
                    </div>
                )}
            </div>
        </>
    );
};

const styles = {
    container: {
        display: 'flex',
        flexDirection: 'column',
        minWidth: '100%',
        alignItems: 'center',
        justifyContent: 'center',
        // padding: '20px',
        color: 'black',
        marginTop: '8vh'
    },
    form: {
        maxWidth: '800px',
        width: '100%',
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    },
    header: {
        textAlign: 'center',
        marginBottom: '20px',
    },
    formGrid: {
        display: 'grid',
        gridTemplateColumns: 'repeat(auto-fill, minmax(200px, 1fr))',
        gap: '50px',
    },
    formGroup: {
        display: 'flex',
        flexDirection: 'column',
    },
    label: {
        marginBottom: '5px',
        fontWeight: 'bold',
    },
    input: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    fileInput: {
        padding: '5px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    textarea: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
        resize: 'vertical',
        minHeight: '80px',
    },
    select: {
        padding: '10px',
        borderRadius: '4px',
        border: '1px solid #ccc',
    },
    submitButton: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '20px',
        alignSelf: 'center',
    },
    progressContainer: {
        marginTop: '20px',
        textAlign: 'center',
    },
    progress: {
        width: '100%',
        height: '20px',
    },
    logContainer: {
        marginTop: '20px',
        padding: '10px',
        backgroundColor: '#f9f9f9',
        borderRadius: '4px',
        width: '100%',
        maxWidth: '800px',
    },
    logList: {
        listStyleType: 'none',
        padding: '0',
    },
    modal: {
        position: 'fixed',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    modalContent: {
        backgroundColor: '#fff',
        padding: '20px',
        borderRadius: '8px',
        textAlign: 'center',
    },
    modalButton: {
        padding: '10px 20px',
        backgroundColor: '#007BFF',
        color: '#fff',
        border: 'none',
        borderRadius: '4px',
        cursor: 'pointer',
        marginTop: '10px',
    },
};

export default CMSMediaForm;
