import React, { useEffect, useState, useRef } from 'react';
import axios from 'axios';
import { useParams, useNavigate } from 'react-router-dom';
import ProfilePage from './Components/ProfilePage';
import Post from './Component-4/Post';
import AdPage from './AdComponent/AdPage';
import { Box, Grid, Button } from '@mui/material';
import 'bootstrap/dist/css/bootstrap.min.css';
import PostForm from './PostForm';
// import VideoUploadForm from './VideoUploadForm';
import UserSizzlers from './userSizzlers';

function MainPage() {
  const [user, setUser] = useState(null);
  const [posts, setPosts] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const { userId } = useParams();
  const navigate = useNavigate(); // Use navigate for routing

  const token = localStorage.getItem('token');
  axios.defaults.headers.common['x-auth-token'] = token;
  axios.defaults.baseURL = 'http://103.27.233.190:5000/api';


  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await axios.get(`http://103.27.233.190:5000/api/admin/users/${userId}`, {
          headers: {
            'x-auth-token': token
          }
        });
        setUser(response.data);

        fetchUserPosts(userId);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    const fetchUserPosts = async (userId) => {
      try {
        const response = await axios.get(`http://103.27.233.190:5000/api/admin/posts/user/${userId}`, {
          headers: {
            'x-auth-token': token
          }
        });
        setPosts(response.data);
      } catch (error) {
        console.error('Error fetching user posts:', error);
      }
    };

    fetchUserData();
  }, [token, userId]);



  // const handleVideoUpload = (newVideo) => {
  //   setPosts([newVideo, ...posts]);
  // };

  const handleProfilePictureUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append('profilePicture', selectedFile);
      try {
        const response = await axios.put('http://103.27.233.190:5000/api/admin/superuser/profile-picture', formData, {
          headers: {
            'x-auth-token': localStorage.getItem('token'),
            'Content-Type': 'multipart/form-data',
          }
        });
        setUser({ ...user, profilePicture: response.data.profilePicture });
      } catch (error) {
        console.error('Error updating profile picture:', error);
      }
    } else {
      console.error('No file selected');
    }
  };

  const handleCreatePost = async (formData) => {
    try {
      const response = await axios.post('http://103.27.233.190:5000/api/admin/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      setPosts([response.data, ...posts]);
    } catch (error) {
      console.error('Error creating post:', error);
    }
  };

  const handleLike = async (postId) => {
    try {
      const response = await axios.put(`http://103.27.233.190:5000/api/admin/posts/${postId}/like`, null, {
        headers: {
          'x-auth-token': token
        }
      });
      setPosts(posts.map(post => post._id === postId ? response.data : post));
    } catch (error) {
      console.error('Error liking post:', error);
    }
  };

  const handleComment = async (postId, text) => {
    try {
      const response = await axios.post(`http://103.27.233.190:5000/api/admin/posts/${postId}/comment`, { text }, {
        headers: {
          'x-auth-token': token
        }
      });
      setPosts(posts.map(post => post._id === postId ? response.data : post));
    } catch (error) {
      console.error('Error commenting on post:', error);
    }
  };

  const handleShare = async (postId) => {
    try {
      const response = await axios.put(`http://103.27.233.190:5000/api/admin/posts/${postId}/share`, null, {
        headers: {
          'x-auth-token': token
        }
      });
      setPosts(posts.map(post => post._id === postId ? response.data : post));
    } catch (error) {
      console.error('Error sharing post:', error);
    }
  };

  const loggedInUser = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null;

  // Handler for navigating to the analytics page
  const handleNavigateToAnalytics = () => {
    navigate(`/analytics/${userId}`);
  };

  const handleNavigateToWebsite = () => {
    navigate('/cmshome'); // Redirect to /cmshome using React Router's navigate function
  };

  return (
    <Box sx={{ marginTop: '5vh', padding: 2, backgroundColor: 'rgba(0,0,0,0.3)', }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={3}>
          <ProfilePage
            loggedInUser={loggedInUser}
            onUpdateProfilePicture={handleProfilePictureUpload}
          />
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ maxHeight: '100vh', overflowY: 'auto', backgroundColor: 'rgba(0,0,0,0.3)', borderRadius: 2, boxShadow: 2, overflowY: "auto", WebkitOverflowScrolling: "touch", scrollbarWidth: "none" }}>
            <PostForm
              loggedInUser={loggedInUser}
              user={user}
              onCreatePost={handleCreatePost}
            />
            {/* <VideoUploadForm
              onVideoUpload={handleVideoUpload}
              user={user}
              loggedInUser={loggedInUser}
            /> */}
            <div className="post-container" style={{ maxHeight: "100vh", backgroundColor: "rgba(0,0,0,0.9)" }}>
              <div style={{ position: "sticky", top: 0, zIndex: 1, backgroundColor: "black" }}>
                <UserSizzlers userId={userId} />
              </div>
              <Post
                posts={posts}
                onLike={handleLike}
                onComment={handleComment}
                onShare={handleShare}
                user={user}
              />
            </div>
          </Box>
        </Grid>
        <Grid item xs={12} md={3}>
          <AdPage />
          {loggedInUser && loggedInUser.role === "super-user" && loggedInUser.id === userId && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateToAnalytics}
              fullWidth
              sx={{ mt: 2 }}
            >
              Go to Analytics
            </Button>
          )}

          {loggedInUser && loggedInUser.role === "super-user" && loggedInUser.id === userId && (
            <Button
              variant="contained"
              color="primary"
              onClick={handleNavigateToWebsite}
              fullWidth
              sx={{ mt: 2 }}
            >
              Channel Management
            </Button>
          )}
        </Grid>
      </Grid>
    </Box>
  );
}

export default MainPage;
