import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './CmsSizzlers.module.css';  // CSS Module
import CmsNavbar from './CmsNavbar';
import Modal from 'react-modal'; // Import react-modal

// You can configure Modal to mount at the root of your app
Modal.setAppElement('#root');

const CmsSizzlers = () => {
  const [sizzlers, setSizzlers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all'); // For filtering (approved, rejected, etc.)
  const [isModalOpen, setIsModalOpen] = useState(false); // Modal state
  const [selectedSizzlerComments, setSelectedSizzlerComments] = useState([]); // Store comments for modal

  const token = localStorage.getItem('token'); // Get token from localStorage

  useEffect(() => {
    const fetchSizzlers = async () => {
      if (!token) {
        setError('No token found. Please log in again.');
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get('http://thecameratales.com:5000/api/video/allposts', {
          headers: {
            'x-auth-token': token, // Pass token in headers
          },
        });
        setSizzlers(response.data);
      } catch (error) {
        setError(error.response?.data || 'Failed to load sizzlers');
      } finally {
        setLoading(false);
      }
    };

    fetchSizzlers();
  }, [token]);

  const filteredSizzlers = sizzlers.filter(sizzler => {
    if (filter === 'all') return true;
    if (filter === 'approved') return sizzler.approved === true;
    if (filter === 'rejected') return sizzler.rejected === true;
    if (filter === 'pending') return sizzler.pending === true;
  });

  // Function to open modal and show all comments
  const handleShowMoreComments = (comments) => {
    setSelectedSizzlerComments(comments); // Set the selected sizzler's comments
    setIsModalOpen(true); // Open the modal
  };

  // Function to close modal
  const closeModal = () => {
    setIsModalOpen(false);
    setSelectedSizzlerComments([]);
  };

  if (loading) {
    return <p className={styles.loadingMsg}>Loading sizzlers...</p>;
  }

  if (error) {
    return <p className={styles.errorMsg}>{error}</p>;
  }

  return (
    <>
      <CmsNavbar />
      <div className={styles.sizzlersContainer}>
        {/* Sidebar with filters */}
        <div className={styles.sidebar}>
          <h2>Sizzlers-Posts</h2>
          <h3>Filters</h3>
          <button className={styles.filterButton} onClick={() => setFilter('all')}>All</button>
          <button className={styles.filterButton} onClick={() => setFilter('approved')}>Approved</button>
          <button className={styles.filterButton} onClick={() => setFilter('pending')}>Pending</button>
          <button className={styles.filterButton} onClick={() => setFilter('rejected')}>Rejected</button>
        </div>

        {/* Sizzlers Grid */}
        <div className={styles.sizzlersGrid}>
          {filteredSizzlers.length === 0 ? (
            <p className={styles.noSizzlersMsg}>No sizzlers found.</p>
          ) : (
            filteredSizzlers.map((sizzler) => (
              <div key={sizzler._id} className={styles.sizzlerCard}>
                <h3 className={styles.sizzlerChannel}>{sizzler.channel}</h3>
                
                {/* Channel logo */}
                {sizzler.channelLogo && (
                  <img
                    src={`http://thecameratales.com:5000/uploads/${sizzler.channelLogo}`}
                    alt="Channel Logo"
                    className={styles.channelLogo}
                  />
                )}

                {/* Video element */}
                {sizzler.videoPath && (
                  <video controls className={styles.sizzlerVideo}>
                    <source src={`http://thecameratales.com:5000/uploads/${sizzler.videoPath}`} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                )}

                {/* Sizzler details */}
                <p className={styles.sizzlerDescription}>{sizzler.description}</p>
                <p className={styles.sizzlerInfo}>
                  Likes: <span>{sizzler.likes.length}</span> | Dislikes: <span>{sizzler.dislikes.length}</span> | Shares: <span>{sizzler.shares}</span>
                </p>
                <p className={styles.sizzlerInfo}>
                  Posted by: {sizzler.userId?.firstName || 'Unknown'} {sizzler.userId?.lastName || 'User'}
                </p>
                <p className={styles.sizzlerStatus}>
                  Status: {sizzler.approved ? 'Approved' : sizzler.rejected ? 'Rejected' : 'Pending'}
                </p>

                {/* Comments section */}
                <h4 className={styles.commentsHeading}>Comments:</h4>
                {sizzler.comments.length > 0 ? (
                  <ul className={styles.commentsList}>
                    {/* Show only first two comments */}
                    {sizzler.comments.slice(0, 2).map((comment, idx) => (
                      <li key={idx} className={styles.commentItem}>
                        <p className={styles.commentText}>{comment.commentText}</p>
                        <p className={styles.commentAuthor}>
                          By: {comment.commenterFirstName || 'Unknown'} {comment.commenterLastName || 'User'}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noCommentsMsg}>No comments yet.</p>
                )}

                {/* Show more comments link */}
                {sizzler.comments.length > 2 && (
                  <p className={styles.showMore} onClick={() => handleShowMoreComments(sizzler.comments)}>
                    Show more comments
                  </p>
                )}
              </div>
            ))
          )}
        </div>
      </div>

      {/* Modal to show all comments */}
      <Modal 
        isOpen={isModalOpen} 
        onRequestClose={closeModal} 
        contentLabel="All Comments"
        className={styles.modal}
        overlayClassName={styles.modalOverlay}
      >
        <h2>All Comments</h2>
        <button onClick={closeModal} className={styles.closeModalBtn}>Close</button>
        <ul className={styles.modalCommentsList}>
          {selectedSizzlerComments.map((comment, idx) => (
            <li key={idx} className={styles.modalCommentItem}>
              <p className={styles.modalCommentText}>{comment.commentText}</p>
              <p className={styles.modalCommentAuthor}>
                By: {comment.commenterFirstName || 'Unknown'} {comment.commenterLastName || 'User'}
              </p>
            </li>
          ))}
        </ul>
      </Modal>
    </>
  );
};

export default CmsSizzlers;
