import React, { useEffect, useState, useCallback } from "react";
import { Modal, List, ListItem, ListItemText, ListItemSecondaryAction} from "@mui/material";
import { Close } from "@mui/icons-material";
import axios from "axios";
import {
  Box,
  Button,
  CircularProgress,
  Stack,
  Typography,
  Card,
  CardMedia,
  CardContent,
  IconButton,
  Avatar,
  Tooltip,
} from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";
import {
  LinkedIn,
  Instagram,
  Twitter,
  EmojiEvents,
  Public,
} from "@mui/icons-material";
import {
  Favorite,
  Comment,
  Share,
  Visibility,
} from "@mui/icons-material"; // Import icons for likes, comments, and shares
// import { FaEye } from 'react-icons/fa';

const CastPortfolio = () => {
  const { castId } = useParams();
  const navigate = useNavigate();

  const [cast, setCast] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const { mediaType } = useParams();
  const [isFollowing, setIsFollowing] = useState(false); // To track following status
  const userId = JSON.parse(localStorage.getItem('user')).id; // Logged-in user's ID
  const token = localStorage.getItem('token');
  const [followers, setFollowers] = useState([]);
  const [following, setFollowing] = useState([]);
  const [showFollowersModal, setShowFollowersModal] = useState(false);
  const [showFollowingModal, setShowFollowingModal] = useState(false);
  const [userList, setUserList] = useState([]);

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const fetchCastDetails = async () => {
      try {
        const token = localStorage.getItem('token');
        const { data } = await axios.get(
          `${apiBaseURL}/api/admin/users/${castId}`,
          {
            headers: { 'x-auth-token': token },
          }
        );
        setCast(data);
        // Check if the logged-in user is already following this cast
        const isUserFollowing = data.followers.includes(userId);
        setIsFollowing(isUserFollowing);

        setLoading(false);
      } catch (err) {
        setError(err.message);
        setLoading(false);
      }
    };

    fetchCastDetails();
  }, [castId, userId, token]);

  const handleShowFollowers = async () => {
    try {
      const { data } = await axios.get(
        `${apiBaseURL}/api/users/${castId}/followers`,
        { headers: { 'x-auth-token': token } }
      );
      setUserList(data.followers);
      setShowFollowersModal(true);
    } catch (err) {
      console.error("Error fetching followers:", err);
    }
  };
  
  const handleShowFollowing = async () => {
    try {
      const { data } = await axios.get(
        `${apiBaseURL}/api/users/${castId}/following`,
        { headers: { 'x-auth-token': token } }
      );
      setUserList(data.following);
      setShowFollowingModal(true);
    } catch (err) {
      console.error("Error fetching following:", err);
    }
  };

  const handleRemoveUser = async (targetUserId) => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userId}/unfollow`,
        { targetUserId },
        { headers: { 'x-auth-token': token } }
      );
      setUserList((prevList) => prevList.filter(user => user.id !== targetUserId));
      fetchFollowersAndFollowing();
    } catch (err) {
      console.error("Error removing user:", err);
    }
  };
  


  // Define fetchFollowersAndFollowing using useCallback
  const fetchFollowersAndFollowing = useCallback(async () => {
    try {
      const [followersRes, followingRes] = await Promise.all([
        axios.get(`${apiBaseURL}/api/users/${castId}/followers`, {
          headers: { 'x-auth-token': token },
        }),
        axios.get(`${apiBaseURL}/api/users/${castId}/following`, {
          headers: { 'x-auth-token': token },
        }),
      ]);

      setFollowers(followersRes.data.followers);
      setFollowing(followingRes.data.following);
    } catch (err) {
      console.error("Error fetching followers/following:", err);
    }
  }, [castId, token]);

  useEffect(() => {
    fetchFollowersAndFollowing();
  }, [fetchFollowersAndFollowing]);

  // Handle follow button click
  const handleFollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userId}/follow`,
        { targetUserId: castId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(true); // Update UI after successful follow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error following user:", err);
    }
  };

  // Handle unfollow button click
  const handleUnfollow = async () => {
    try {
      await axios.put(
        `${apiBaseURL}/api/users/${userId}/unfollow`,
        { targetUserId: castId },
        { headers: { 'x-auth-token': token } }
      );
      setIsFollowing(false); // Update UI after successful unfollow
      fetchFollowersAndFollowing()
    } catch (err) {
      console.error("Error unfollowing user:", err);
    }
  };

  const handleCloseModal = () => {
    setShowFollowersModal(false);
    setShowFollowingModal(false);
  };


  if (loading) return <CircularProgress />;
  if (error) return <Typography color="error">Error: {error}</Typography>;

  return (
    cast && (
      <Box
        sx={{
          padding: 3,
          bgcolor: '#121212',
          minHeight: '100vh',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          animation: 'fadeIn 1s ease',
          color: 'white',
          marginTop: "5vh"
        }}
      >
        <Stack spacing={4} sx={{ width: '100%' }}>
          {/* Cast Header */}
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              textAlign: 'center',
              alignItems: 'center',
              justifyContent: 'space-between',
              textAlign: 'center',
              borderBottom: '1px solid #333',
              pb: 2,
            }}
          >
            <Avatar
              src={`${apiBaseURL}/uploads/${cast.profilePicture}`}
              alt={`${cast.firstName} ${cast.lastName}`}
              sx={{
                width: 150,
                height: 150,
                boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                border: '3px solid #FFD700',
              }}
            />
            <Box sx={{ textAlign: 'left', ml: 3, flexGrow: 1 }}>
              <Typography variant="h3" sx={{ fontWeight: 'bold' }}>
                {`${cast.firstName} ${cast.lastName}`}
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: 'grey' }}>
                <span onClick={handleShowFollowers} style={{ cursor: 'pointer' }}>Followers: {followers.length}</span>
                <span onClick={handleShowFollowing} style={{ margin: '1vw', cursor: 'pointer', color: 'grey' }}>Following: {following.length}</span>
              </Typography>
              </Typography>
              <Typography variant="body1" sx={{ color: '#AAA', mt: 1 }}>
                <strong>Occupation:</strong> {cast.occupation}
              </Typography>
              <Typography variant="body1" sx={{ color: '#AAA', mt: 1 }}>
                <strong>Born:</strong> {new Date(cast.birthDate).toLocaleDateString()}
              </Typography>
              <Typography variant="body1" sx={{ color: '#AAA', mt: 1 }}>
                <strong>Address:</strong> {cast.contactInfo.address.city}, {cast.contactInfo.address.country}
              </Typography>
            </Box>
             {/* Modals */}
          <Modal open={showFollowersModal || showFollowingModal} onClose={handleCloseModal}>
            <Box sx={{ bgcolor: '#fff', width: '400px', margin: '10vh auto', padding: '20px', borderRadius: '10px' }}>
              <IconButton sx={{ float: 'right' }} onClick={handleCloseModal}><Close /></IconButton>
              <Typography variant="h6" sx={{ mb: 2 }}>{showFollowersModal ? "Followers" : "Following"}</Typography>
              <List>
                {(showFollowersModal ? followers : following).map((user, index) => (
                  <ListItem key={index}>
                    <Avatar src={`${apiBaseURL}/uploads/${user.profilePicture}`} alt={user.name} sx={{ mr: 2 }} />
                    <ListItemText primary={`${user.firstName} ${user.lastName}`} />
                  </ListItem>
                ))}
              </List>
            </Box>
          </Modal>
            {/* Follow/Unfollow Button */}
            <Box>
              {isFollowing ? (
                <Button
                  variant="outlined"
                  color="error"
                  onClick={handleUnfollow}
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  Unfollow
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleFollow}
                  sx={{ fontSize: "1rem", fontWeight: "bold" }}
                >
                  Follow
                </Button>
              )}
            </Box>
          </Box>

          {/* Biography */}
          <Box sx={{ mt: 2 }}>
            <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 1 }}>
              Biography
            </Typography>
            <Typography variant="body1" sx={{ color: '#AAA' }}>
              {cast.biography}
            </Typography>
          </Box>

          {/* Social Links */}
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              gap: 3,
              mt: 2,
              mb: 4,
            }}
          >
            {cast.socialLinks?.map((link, index) => (
              <Tooltip title={link.type} key={index}>
                <IconButton
                  href={link.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.2)', color: '#FFD700' },
                    backgroundColor: '#333',
                    borderRadius: '50%',
                    p: 1,
                  }}
                >
                  {link.type === 'LinkedIn' && <LinkedIn />}
                  {link.type === 'Instagram' && <Instagram />}
                  {link.type === 'Twitter' && <Twitter />}
                </IconButton>
              </Tooltip>
            ))}
            {cast.website && (
              <Tooltip title="Website">
                <IconButton
                  href={cast.website}
                  target="_blank"
                  rel="noopener noreferrer"
                  sx={{
                    transition: 'transform 0.3s',
                    '&:hover': { transform: 'scale(1.2)', color: '#FFD700' },
                    backgroundColor: '#333',
                    borderRadius: '50%',
                    p: 1,
                  }}
                >
                  <Public />
                </IconButton>
              </Tooltip>
            )}
          </Box>

          {/* Awards Section */}
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
              }}
            >
              Awards & Achievements
            </Typography>
            <Stack spacing={2} sx={{ mt: 2 }}>
              {cast.awards?.length > 0 ? (
                cast.awards.map((award, index) => (
                  <Box key={index} sx={{ display: 'flex', alignItems: 'center' }}>
                    <EmojiEvents sx={{ mr: 2, color: '#FFD700' }} />
                    <Typography>{`${award.name} - ${award.year}`}</Typography>
                  </Box>
                ))
              ) : (
                <Typography>No awards available</Typography>
              )}
            </Stack>
          </Box>

          {/* Movies Section */}
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
              }}
            >
              Movies
            </Typography>
            <Swiper
              spaceBetween={15}
              slidesPerView={4}
              breakpoints={{
                0: { slidesPerView: 2 },
                600: { slidesPerView: 3 },
                960: { slidesPerView: 4 },
                1280: { slidesPerView: 5 }
              }}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              style={{ width: '100%', paddingTop: '15px' }}
            >

              {cast.movies?.map(movie => (
                <SwiperSlide key={movie.mediaId}>
                  <Card
                    sx={{
                      backgroundColor: '#1c1c1c',
                      borderRadius: 2,
                      boxShadow: 5,
                      '&:hover': {
                        boxShadow: '0px 8px 16px rgba(0, 0, 0, 0.5)',
                      },
                    }}
                  >
                    <CardMedia
                      component="img"
                      height="300"
                      image={`${apiBaseURL}/uploads/${movie.poster_path}`}
                      alt={movie.title}
                      sx={{
                        objectFit: 'cover',
                        borderTopLeftRadius: 8,
                        borderTopRightRadius: 8,
                        cursor: 'pointer',
                      }}
                    // onClick={() => navigate(`/${mediaType}/${movie.mediaId}`)}
                    />
                    <CardContent sx={{ color: 'white' }}>
                      <Typography
                        variant="h6"
                        component="div"
                        sx={{
                          overflow: 'hidden',
                          textOverflow: 'ellipsis',
                          whiteSpace: 'nowrap',
                          fontSize: '1rem',
                          mb: 1,
                        }}
                      >
                        {movie.title}
                      </Typography>
                      <Typography variant="body2" color="#FFD700">
                        {new Date(movie.release_date).toLocaleDateString()} - {movie.genre.join(', ')}
                      </Typography>

                      {/* Display likes, comments, and shares */}
                      <Stack direction="row" spacing={2} sx={{ mt: 2 }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Favorite sx={{ color: '#FFD700', mr: 0.5 }} />
                          <Typography variant="body2">{movie.likes}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Comment sx={{ color: '#FFD700', mr: 0.5 }} />
                          <Typography variant="body2">{movie.comments.length}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Share sx={{ color: '#FFD700', mr: 0.5 }} />
                          <Typography variant="body2">{movie.shares}</Typography>
                        </Box>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          < Visibility sx={{ color: '#FFD700', mr: 0.5 }} />
                          <Typography variant="body2">{movie.views}</Typography>
                        </Box>
                      </Stack>
                    </CardContent>
                  </Card>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          {/* Peers Section */}
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
              }}
            >
              Peers
            </Typography>
            <Swiper
              spaceBetween={15}
              slidesPerView={15}
              breakpoints={{
                0: { slidesPerView: 2 },
                600: { slidesPerView: 3 },
                960: { slidesPerView: 4 },
                1280: { slidesPerView: 5 }
              }}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              style={{ width: '100%', paddingTop: '15px' }}
            >
              {cast.peers?.map(peer => (
                <SwiperSlide key={peer.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/cast/${castId}`)}
                  >
                    <Avatar
                      src={`${apiBaseURL}/uploads/${peer.profilePicture}`}
                      alt={peer.name}
                      sx={{
                        width: 100,
                        height: 100,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                        mb: 1,
                      }}
                    />
                    <Typography variant="body2" color="#FFD700">
                      {peer.name}
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          {/* Family Section */}
          <Box>
            <Typography
              variant="h5"
              sx={{
                fontWeight: 'bold',
                mb: 2,
                borderBottom: '2px solid #FFD700',
                display: 'inline-block',
              }}
            >
              Family
            </Typography>
            <Swiper
              spaceBetween={15}
              slidesPerView={15}
              breakpoints={{
                0: { slidesPerView: 2 },
                600: { slidesPerView: 3 },
                960: { slidesPerView: 4 },
                1280: { slidesPerView: 5 }
              }}
              navigation
              pagination={{ clickable: true }}
              modules={[Navigation, Pagination]}
              style={{ width: '100%', paddingTop: '15px' }}
            >
              {cast.family?.map(member => (
                <SwiperSlide key={member.id}>
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'column',
                      alignItems: 'center',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/cast/${castId}`)}
                  >
                    <Avatar
                      src={`${apiBaseURL}/uploads/${member.profilePicture}`}
                      alt={member.name}
                      sx={{
                        width: 100,
                        height: 100,
                        boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.5)',
                        mb: 1,
                      }}
                    />
                    <Typography variant="body2" color="#FFD700">
                      {member.name}
                    </Typography>
                  </Box>
                </SwiperSlide>
              ))}
            </Swiper>
          </Box>

          {/* Profile Button */}
          <Button
            variant="contained"
            sx={{
              alignSelf: 'center',
              mt: 3,
              backgroundColor: '#FFD700',
              color: '#121212',
              '&:hover': { backgroundColor: '#FFC107' },
            }}
            onClick={() => navigate(`/users/${castId}`)}
          >
            View Full Profile
          </Button>
        </Stack>
      </Box>
    )
  );
};

export default CastPortfolio;
