import { NavLink } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import { Home, Movie, Tv, Category, Folder, Star, Theaters, PlaylistPlay, QueueMusic, Favorite, WatchLater, VideoLibrary, ExpandMore, ExpandLess, Search, Settings, TheaterComedy, CurrencyRupee } from "@mui/icons-material";
import { useMediaQuery, Collapse, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import { useState } from "react";

// Masks: SvgIconComponent;
// export const MasksOutlined: SvgIconComponent;
// export const MasksRounded: SvgIconComponent;
// export const MasksSharp: SvgIconComponent;
// export const MasksTwoTone

const routes = [
  {
    path: "/search",
    name: "Search",
    icon: Search,
  },
  {
    path: "/",
    name: "Home",
    icon: Home,
  },
];

const categories = [
  {
    path: "/movie",
    name: "Movies",
    icon: Movie,
  },
  {
    path: "/fridaytalkies",
    name: "Friday Talkies",
    icon: Theaters,
  },
  {
    path: "/webseries",
    name: "Web Series",
    icon: PlaylistPlay,
  },
  {
    path: "/shows",
    name: "Web Shows",
    icon: Tv,
  },
  {
    path: "/musicvideos",
    name: "Music Videos",
    icon: QueueMusic,
  },
  {
    path: "/favorites",
    name: "Favorites",
    icon: Favorite,
  },
  {
    path: "/toptalesthisweek",
    name: "Top Tales This Week",
    icon: Star,
  },
  {
    path: "/shortfilms",
    name: "Short Films",
    icon: VideoLibrary,
  },
  {
    path: "/watchlist",
    name: "Watchlist",
    icon: WatchLater,
  },
];

const additionalRoutes = [
  {
    path: "/artists",
    name: "Artists",
    icon: TheaterComedy,
  },
  {
    path: "/subscription",
    name: "Subscription",
    icon: CurrencyRupee,
  },
  {
    path: "/profilesettings",
    name: "Settings",
    icon: Settings,
  },
];

const SideBarDiv = ({ isOpen, toggleSidebar }) => {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));
  const isMediumScreen = useMediaQuery((theme) => theme.breakpoints.down("md"));
  const [categoriesOpen, setCategoriesOpen] = useState(false);

  const showAnimation = {
    hidden: {
      width: 0,
      opacity: 0,
      transition: {
        duration: 0.5,
      },
    },
    show: {
      opacity: 1,
      width: "auto",
      transition: {
        duration: 0.5,
      },
    },
  };

  const handleCategoriesToggle = () => {
    setCategoriesOpen(!categoriesOpen);
  };

  return (
    <motion.div
      className="sidebar"
      initial={{ width: "60px", margin: 0 }}
      animate={{ width: isSmallScreen ? (isOpen ? "250px" : "0px") : (isOpen ? "250px" : "60px") }}
      transition={{ duration: 0.5, type: "spring", damping: 10 }}
      style={{
        display: "flex",
        flexDirection: "column",
        position: isSmallScreen || isMediumScreen ? "fixed" : "sticky",
        left: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "black",
        color: "white",
        fontWeight: "bolder",
        fontSize: "16px",
        overflowY: "auto",
        zIndex: 9999,
        height: "100vh",
        overflowX: "hidden",
        scrollbarWidth: "none",
        "&::-webkit-scrollbar": {
          width: "0 !important",
        },
      }}
    >
      <section className="routes" style={{ marginTop: "15vh" }}>
        {routes.map((route, index) => {
          const Icon = route.icon;

          return (
            <NavLink
              to={route.path}
              key={index}
              className="link"
              style={{
                backgroundColor: "black",
                color: "white",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                margin: "5px",
                borderRadius: "8px",
                transition: "background-color 0.3s, transform 0.3s, box-shadow 0.3s",
              }}
              activeStyle={{
                backgroundColor: "white",
                color: "black",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#333";
                e.target.style.transform = "scale(1.05)";
                e.target.style.boxShadow = "0 4px 12px rgba(0,0,0,0.3)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.transform = "scale(1)";
                e.target.style.boxShadow = "none";
              }}
            >
              <Icon style={{ color: "white", marginRight: "5px", fontSize: "20px" }} />
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          );
        })}

        {/* Categories Dropdown */}
        <List component="nav" style={{ padding: 0 }}>
          <ListItem
            button
            onClick={handleCategoriesToggle}
            style={{
              backgroundColor: "black",
              color: "white",
              display: "flex",
              alignItems: "center",
              padding: "10px",
              margin: "5px",
              cursor: "pointer",
              borderRadius: "8px",
              transition: "background-color 0.3s, transform 0.3s, box-shadow 0.3s",
            }}
            onMouseEnter={(e) => {
              e.target.style.backgroundColor = "#333";
              e.target.style.transform = "scale(1.05)";
              e.target.style.boxShadow = "0 4px 12px rgba(0,0,0,0.3)";
            }}
            onMouseLeave={(e) => {
              e.target.style.backgroundColor = "black";
              e.target.style.transform = "scale(1)";
              e.target.style.boxShadow = "none";
            }}
          >
            <ListItemIcon>
              <Category style={{ color: "white", fontSize: "20px",}} />
            </ListItemIcon>
            <AnimatePresence>
              {isOpen && (
                <motion.div
                  variants={showAnimation}
                  initial="hidden"
                  animate="show"
                  exit="hidden"
                >
                  <ListItemText
                    primary="Categories"
                    style={{ color: "white", fontWeight: "bolder", marginLeft:"-1vw" }}
                  />
                </motion.div>
              )}
            </AnimatePresence>
            {categoriesOpen ? <ExpandLess style={{ color: "white" }} /> : <ExpandMore style={{ color: "white" }} />}
          </ListItem>
          <Collapse in={categoriesOpen} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {categories.map((category, index) => (
                <ListItem
                  button
                  key={index}
                  component={NavLink}
                  to={category.path}
                  style={{
                    paddingLeft: "30px",
                    backgroundColor: "black",
                    color: "white",
                    borderRadius: "8px",
                    transition: "background-color 0.3s, transform 0.3s, box-shadow 0.3s",
                  }}
                  activeStyle={{
                    backgroundColor: "white",
                    color: "black",
                    boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
                  }}
                  onMouseEnter={(e) => {
                    e.target.style.backgroundColor = "#333";
                    e.target.style.transform = "scale(1.05)";
                    e.target.style.boxShadow = "0 4px 12px rgba(0,0,0,0.3)";
                  }}
                  onMouseLeave={(e) => {
                    e.target.style.backgroundColor = "black";
                    e.target.style.transform = "scale(1)";
                    e.target.style.boxShadow = "none";
                  }}
                >
                  <ListItemIcon>
                    <category.icon style={{ color: "white", fontSize: "20px" }} />
                  </ListItemIcon>
                  <ListItemText primary={category.name} />
                </ListItem>
              ))}
            </List>
          </Collapse>
        </List>


        {/* Additional Routes after Categories */}
        {additionalRoutes.map((route, index) => {
          const Icon = route.icon;

          return (
            <NavLink
              to={route.path}
              key={index}
              className="link"
              style={{
                backgroundColor: "black",
                color: "white",
                textDecoration: "none",
                display: "flex",
                alignItems: "center",
                padding: "10px",
                margin: "5px",
                borderRadius: "8px",
                transition: "background-color 0.3s, transform 0.3s, box-shadow 0.3s",
              }}
              activeStyle={{
                backgroundColor: "white",
                color: "black",
                boxShadow: "0 4px 8px rgba(0,0,0,0.2)",
              }}
              onMouseEnter={(e) => {
                e.target.style.backgroundColor = "#333";
                e.target.style.transform = "scale(1.05)";
                e.target.style.boxShadow = "0 4px 12px rgba(0,0,0,0.3)";
              }}
              onMouseLeave={(e) => {
                e.target.style.backgroundColor = "black";
                e.target.style.transform = "scale(1)";
                e.target.style.boxShadow = "none";
              }}
            >
              <Icon style={{ color: "white", marginRight: "5px", fontSize: "20px" }} />
              <AnimatePresence>
                {isOpen && (
                  <motion.div
                    variants={showAnimation}
                    initial="hidden"
                    animate="show"
                    exit="hidden"
                    className="link_text"
                  >
                    {route.name}
                  </motion.div>
                )}
              </AnimatePresence>
            </NavLink>
          );
        })}
      </section>
    </motion.div>
  );
};

export default SideBarDiv;
