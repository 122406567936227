import React, { useEffect, useState } from "react";
import axios from "axios";
import Videos from "./component/Videos";
import { jwtDecode } from "jwt-decode";
import { useSwipeable } from "react-swipeable";
import { useParams } from "react-router-dom";
import "./SizzlersVid.css";

function SizzlersVid({ selectedMedia }) {
  const { videoId } = useParams();
  const [ytVideo, setYtVideo] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [userId, setUserId] = useState(null);
  const [commenterName, setCommenterName] = useState("");

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const token = localStorage.getItem('token'); 
    if (token) {
      const decoded = jwtDecode(token);
      setUserId(decoded._id);
    }
  }, []);

  useEffect(() => {
    async function fetchCommenterName() {
      try {
        if (userId) {
          const userDetailsResponse = await axios.get(`${apiBaseURL}/api/users/${userId}`, {
            headers: {
              'x-auth-token': localStorage.getItem('token')
            }
          });
          setCommenterName(userDetailsResponse.data.firstName);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchCommenterName();
  }, [userId]);

  useEffect(() => {
    async function fetchVideos() {
      try {
        const response = await axios.get(`${apiBaseURL}/api/video/posts`, {
          headers: {
            'x-auth-token': localStorage.getItem('token')
          }
        });
        setYtVideo(response.data);
        if (selectedMedia) {
          const initialIndex = response.data.findIndex(video => video._id === selectedMedia._id);
          setCurrentIndex(initialIndex !== -1 ? initialIndex : 0);
        }
      } catch (err) {
        console.log(err);
      }
    }
    fetchVideos();
  }, [selectedMedia]);

  const handleLike = async (id) => {
    try {
      if (!userId) {
        console.log('User ID is not available.');
        return;
      }
      const response = await axios.patch(`${apiBaseURL}/api/video/${id}/like`, {}, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setYtVideo((prevVideos) =>
        prevVideos.map((video) => (video._id === id ? response.data : video))
      );
    } catch (err) {
      console.log(err);
      if (err.response && err.response.status === 400) {
        alert("You've already liked this video.");
      } else {
        alert("An error occurred while liking the video.");
      }
    }
  };

  const handleDislike = async (id) => {
    try {
      const response = await axios.patch(`${apiBaseURL}/api/video/${id}/dislike`, { userId }, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setYtVideo((prevVideos) =>
        prevVideos.map((video) => (video._id === id ? response.data : video))
      );
    } catch (err) {
      console.log(err);
    }
  };

  const handleShare = async (id) => {
    try {
      await axios.patch(`${apiBaseURL}/api/video/${id}/share`, { userId }, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      const videoLink = `http://localhost:3000/video/${id}`;
      if (navigator.share) {
        await navigator.share({
          title: "Check out this video!",
          url: videoLink,
        });
      } else {
        navigator.clipboard.writeText(videoLink);
        alert("Link copied to clipboard!");
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleComment = async (id, comment) => {
    try {
      if (!userId) {
        console.log('User ID is not available.');
        return;
      }
      const response = await axios.patch(`${apiBaseURL}/api/video/${id}/comment`, { comment, commenterName }, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      setYtVideo((prevVideos) =>
        prevVideos.map((video) => (video._id === id ? response.data : video))
      );
    } catch (err) {
      console.log('Error posting comment:', err); // Log the error
      alert("An error occurred while posting the comment.");
    }
  };

  const handleSwipe = (direction) => {
    if (direction === "LEFT" && currentIndex < ytVideo.length - 1) {
      setCurrentIndex(currentIndex + 1);
    } else if (direction === "RIGHT" && currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => handleSwipe("LEFT"),
    onSwipedRight: () => handleSwipe("RIGHT"),
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="app" style={{border:'none'}}>
      <div className="app__videos" style={{border:'none'}} {...handlers}>
        {ytVideo.length > 0 && (
          <Videos
            key={ytVideo[currentIndex]._id}
            id={ytVideo[currentIndex]._id}
            src={`${apiBaseURL}/uploads/${ytVideo[currentIndex].videoPath}`}
            channel={ytVideo[currentIndex].channel}
            channelLogo={ytVideo[currentIndex].channelLogo}
            description={ytVideo[currentIndex].description}
            initialLikes={ytVideo[currentIndex].likes.length}
            initialDislikes={ytVideo[currentIndex].dislikes.length}
            initialShares={ytVideo[currentIndex].shares}
            initialComments={ytVideo[currentIndex].comments.map(comment => ({
              commenterName: commenterName,
              commentText: comment.commentText
            }))}
            onLike={() => handleLike(ytVideo[currentIndex]._id)}
            onDislike={() => handleDislike(ytVideo[currentIndex]._id)}
            onShare={() => handleShare(ytVideo[currentIndex]._id)}
            onComment={(comment) => handleComment(ytVideo[currentIndex]._id, comment)}
            isPlaying={true}
          />
        )}
      </div>
    </div>
  );
}

export default SizzlersVid;
