import { Box, CircularProgress } from "@mui/material";
import { Outlet } from "react-router-dom";
import Footer from "../common/Footer";
import Topbar from "../common/Topbar";
import { useDispatch } from "react-redux";
import { useState, useEffect } from "react";
import SideBarDiv from "../common/sideBarDiv";
import { useMediaQuery } from '@mui/material'; // Changed to MUI's useMediaQuery
import logo from '../../assets/TheCameraTales.mp4'

const MainLayout = ({ isOpen, toggle }) => {
  const isSmallScreen = useMediaQuery('(max-width: 600px)'); // Adjusted breakpoint
  const dispatch = useDispatch();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [loading, setLoading] = useState(true); // Loading state

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  // Simulate loading data (you can replace this with actual data fetching logic)
  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     setLoading(false); // Set loading to false after some delay
  //   }, 17000); // Adjust the delay as needed

  //   return () => clearTimeout(timer); // Clean up the timer
  // }, []);

  return (
    <div style={{ margin: 0, padding: 0, height: '100vh' }}>
      <Box display="flex" flexDirection="column" minHeight="100%" sx={{ overflowX: "hidden" }}>
        {/* header */}
        <Topbar toggleSidebar={toggleSidebar} />
        {/* header */}

        <Box display="flex" flexGrow={1}>
          {/* Sidebar */}
          <Box
            sx={{
              position: isSmallScreen ? "fixed" : "fixed", // Allow the sidebar to be fixed for smaller screens
              zIndex: 100,
              display: isSidebarOpen || !isSmallScreen ? "block" : "none", // Sidebar visibility
            }}
          >
            <SideBarDiv isOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          </Box>

          <Box component="main" flexGrow={1} overflow="hidden" sx={{
            marginLeft: isSmallScreen ? '0px' : '50px', // Adjusted margin based on screen size
          }}>
            {/* Loading Spinner */}
            {/* {loading ? (
              <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                <CircularProgress />
                <video src={logo} style={{height: '100%', width: '100%'}} autoPlay muted loop></video>
              </Box>
            ) : ( */}
              <Outlet />
            {/* )} */}
          </Box>
        </Box>
        {/* main */}

        {/* footer */}
        <Footer />
        {/* footer */}
      </Box>
    </div>
  );
};

export default MainLayout;
