import * as React from 'react';
import AnalyticsPage from '../../pages/AnalyticsPage';

const AdPage = ({ username, imageUrl, likes, caption }) => {
  return (

    <div className="container mt-5">
      <AnalyticsPage/>
    </div>
  );
}
 export default AdPage;