import React, { useState, useEffect } from 'react';
import axios from 'axios';
import styles from './UserPosts.module.css';  // Import CSS module
import CmsNavbar from './CmsNavbar';

const CmsUserPosts = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [filter, setFilter] = useState('all');  // State for filtering

  // Fetch the user ID from local storage
  const user = JSON.parse(localStorage.getItem('user'));
  const userId = user ? user.id : null;
  const token = localStorage.getItem('token');  // Get token from localStorage

  useEffect(() => {
    const fetchPosts = async () => {
      if (!token) {
        setError('No token found. Please log in again.');
        return;
      }

      try {
        setLoading(true);
        const response = await axios.get(`http://thecameratales.com:5000/api/admin/userposts/user/${userId}`, {
          headers: {
            'x-auth-token': token,  // Pass token in headers
          },
        });
        setPosts(response.data);
      } catch (error) {
        setError(error.response?.data || 'Failed to load posts');
      } finally {
        setLoading(false);
      }
    };

    if (userId) {
      fetchPosts();
    }
  }, [userId, token]);

  const filteredPosts = posts.filter(post => {
    if (filter === 'all') return true;
    return post.status === filter;
  });

  if (!userId) {
    return <p className={styles.errorMsg}>Please log in to view your posts.</p>;
  }

  if (loading) {
    return <p className={styles.loadingMsg}>Loading posts...</p>;
  }

  if (error) {
    return <p className={styles.errorMsg}>{error}</p>;
  }

  return (
    <>
      <CmsNavbar />
      <div className={styles.postsContainer}>
        {/* Sidebar with filters */}
        <div className={styles.sidebar}>
          <h3>Filters</h3>
          <button 
            className={styles.filterButton} 
            onClick={() => setFilter('all')}
          >
            All
          </button>
          <button 
            className={styles.filterButton} 
            onClick={() => setFilter('approved')}
          >
            Approved
          </button>
          <button 
            className={styles.filterButton} 
            onClick={() => setFilter('pending')}
          >
            Pending
          </button>
          <button 
            className={styles.filterButton} 
            onClick={() => setFilter('rejected')}
          >
            Rejected
          </button>
        </div>

        {/* Posts Grid */}
        <div className={styles.postsGrid}>
          {filteredPosts.length === 0 ? (
            <p className={styles.noPostsMsg}>No posts found.</p>
          ) : (
            filteredPosts.map((post) => (
              <div key={post._id} className={styles.postCard}>
                <h3 className={styles.postCaption}>Caption: {post.caption}</h3>

                {/* Show media (image or video) based on media type */}
                {post.mediaPath && post.mediaType === 'image' && (
                  <img
                    src={`http://thecameratales.com:5000/uploads/${post.mediaPath}`}
                    alt="media"
                    className={styles.postImage}
                  />
                )}

                {post.mediaPath && post.mediaType === 'video' && (
                  <video controls className={styles.postVideo}>
                    <source
                      src={`http://thecameratales.com:5000/uploads/${post.mediaPath}`}
                      type="video/mp4"
                    />
                    Your browser does not support the video tag.
                  </video>
                )}

                <p className={styles.postInfo}>
                  Media Type: <span>{post.mediaType}</span>
                </p>
                <p className={styles.postInfo}>
                  Likes: <span>{post.likes.length}</span>
                </p>
                <p className={styles.postInfo}>
                  Shares: <span>{post.shares.length}</span>
                </p>
                <p className={styles.postInfo}>
                  Posted on: <span>{new Date(post.date).toLocaleDateString()}</span>
                </p>
                <p className={styles.postStatus}>Status: {post.status}</p>

                <h4 className={styles.commentsHeading}>Comments:</h4>
                {post.comments.length > 0 ? (
                  <ul className={styles.commentsList}>
                    {post.comments.map((comment, idx) => (
                      <li key={idx} className={styles.commentItem}>
                        <p className={styles.commentText}>{comment.text}</p>
                        <p className={styles.commentAuthor}>
                          By: {comment.user.firstName || 'Unknown'} {comment.user.lastName || 'Unknown'}
                        </p>
                      </li>
                    ))}
                  </ul>
                ) : (
                  <p className={styles.noCommentsMsg}>No comments yet.</p>
                )}
              </div>
            ))
          )}
        </div>
      </div>
    </>
  );
};

export default CmsUserPosts;
