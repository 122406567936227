import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars, faTimes, faHome, faFilm, faPhotoVideo, faEnvelope, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';

const CmsNavbar = () => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [hasRequestedSuperuser, setHasRequestedSuperuser] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const navigate = useNavigate();

  useEffect(() => {
    fetchUserStatus();

    const handleResize = () => setWindowWidth(window.innerWidth);
    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const fetchUserStatus = async () => {
    try {
      const token = localStorage.getItem('token');
      if (token) {
        const response = await fetch('http://thecameratales.com:5000/api/admin/userreg', {
          method: 'GET',
          headers: {
            'x-auth-token': token,
          },
        });
        const data = await response.json();
        if (data.hasRequestedSuperuser) {
          setHasRequestedSuperuser(true);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleLogout = () => {
    localStorage.removeItem('token');
    navigate('/login');
  };

  const toggleNavbar = () => {
    setIsCollapsed(!isCollapsed);
  };

  // Sidebar styles
  const sidebarStyles = {
    position: 'fixed',
    right: 0,
    top: 0,
    height: '100%',
    marginTop: '7.5vh',
    width: isCollapsed ? '60px' : '250px',
    backgroundColor: 'black',
    transition: 'width 0.3s ease',
    display: windowWidth <= 760 && !isCollapsed ? 'none' : 'flex', // Show sidebar based on collapse state on small screens
    flexDirection: 'column',
    padding: '10px',
    color: 'white',
    zIndex: 1000,
  };

  const toggleButtonStyles = {
    backgroundColor: 'transparent',
    color: 'white',
    cursor: 'pointer',
    border: 'none',
    position: 'fixed',
    right: '10px',
    top: '8vh',
    fontSize: '1.5rem',
    zIndex: 1100,
  };

  const linkStyles = {
    padding: '15px 20px',
    marginTop: '3vh',
    color: 'white',
    textDecoration: 'none',
    display: 'flex',
    alignItems: 'center',
    fontSize: '1rem',
    transition: 'background-color 0.3s ease',
  };

  const btnDangerStyles = {
    color: 'white',
    backgroundColor: 'red',
    border: 'none',
    padding: '10px 20px',
    marginTop: 'auto',
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
  };

  return (
    <>
      <button onClick={toggleNavbar} style={toggleButtonStyles}>
        <FontAwesomeIcon icon={isCollapsed ? faBars : faBars} />
      </button>
      <div style={sidebarStyles}>
        <Link to="/cmshome" style={linkStyles} className="nav-link">
          <FontAwesomeIcon icon={faHome} style={{ marginRight: '10px' }} />
          {!isCollapsed && 'Home'}
        </Link>

        <Link to="/cmsposts" style={linkStyles} className="nav-link">
          <FontAwesomeIcon icon={faFilm} style={{ marginRight: '10px' }} />
          {!isCollapsed && 'Posts'}
        </Link>

        <Link to="/cmssizzlers" style={linkStyles} className="nav-link">
          <FontAwesomeIcon icon={faPhotoVideo} style={{ marginRight: '10px' }} />
          {!isCollapsed && 'Sizzlers'}
        </Link>

        <Link to="/contactus" style={linkStyles} className="nav-link">
          <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
          {!isCollapsed && 'Contact'}
        </Link>

        <Link to="/cmsmedia-form" style={linkStyles} className="nav-link">
          <FontAwesomeIcon icon={faPhotoVideo} style={{ marginRight: '10px' }} />
          {!isCollapsed && 'Upload Media'}
        </Link>

        {!hasRequestedSuperuser && (
          <Link to="/cmsform" style={linkStyles} className="nav-link">
            <FontAwesomeIcon icon={faEnvelope} style={{ marginRight: '10px' }} />
            {!isCollapsed && 'Complete Registration'}
          </Link>
        )}

        <button onClick={handleLogout} style={btnDangerStyles}>
          <FontAwesomeIcon icon={faSignOutAlt} style={{ marginRight: '10px' }} />
          {!isCollapsed && 'Logout'}
        </button>
      </div>
    </>
  );
};

export default CmsNavbar;
