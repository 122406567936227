import axios from "axios";

const baseURL = "http://thecameratales.com:5000/api/"; // Replace with your actual backend API URL

const mediaEndpoints = {
  list: ({ mediaType, mediaCategory, page }) => `${mediaType}/${mediaCategory}?page=${page}`,
  detail: ({ mediaType, mediaId }) => `${mediaType}/detail/${mediaId}`,
  search: ({ mediaType, query, page }) => `${mediaType}/search?query=${query}&page=${page}`,
  favorites: (userId) => `users/${userId}/favorites`,
  viewedMedia: (userId) => `movie/viewed-media`, // Define endpoint for viewed media
  purchaseMovie: (userId, mediaId) => `fridaytalkies/purchase`, // Endpoint for purchasing a movie
  checkPurchaseStatus: () => `fridaytalkies/verify-fridayTpayment`, // Endpoint for checking purchase status
};

const mediaApi = {
  getList: async ({ mediaType, mediaCategory, page }) => {
    try {
      const response = await axios.get(
        baseURL+mediaEndpoints.list({ mediaType, mediaCategory, page })
      );
      return { response };
    } catch (err) { return { err }; }
  },
  getDetail: async ({ mediaType, mediaId }) => {
    try {
      const response = await axios.get(
        baseURL + mediaEndpoints.detail({ mediaType, mediaId })
      );
      const { data } = response;
      // Replace '_id' with 'id' in the response data
      const modifiedData = { ...data, id: data._id };
      delete modifiedData._id; // Remove the '_id' field
      return { response: modifiedData };
    } catch (err) { 
      return { err }; 
    }
  },
  search: async ({ mediaType, query, page }) => {
    try {
      const response = await axios.get(
        baseURL + mediaEndpoints.search({ mediaType, query, page })
      );
      return { response };
    } catch (err) { return { err }; }
  },
  getFavorites: async (userId) => {
    try {
      const response = await axios.get(
        `${baseURL}users/${userId}/favorites`
      );
      return { response: response.data };
    } catch (err) {
      return { err };
    }
  },
  getViewedMedia: async (userId, token) => {
    try {
      if (!userId) throw new Error("User ID is required");
      if (!token) throw new Error("Token is required");
  
      const response = await axios.get(
        `${baseURL}movie/viewed-media`,  // Corrected the URL
        {
          // headers: {
          //   'x-auth-token': token, // Ensure that your backend expects 'x-auth-token'
          // },
          params: { userId }
        }
      );
      
      return { response: response.data };
    } catch (err) {
      console.error('Error fetching viewed media:', err); // Log the error for debugging
      return { err };
    }
  },
   // New method to purchase a movie
   purchaseMovie: async (userId, mediaId) => {
    try {
      const response = await axios.post(
        baseURL + mediaEndpoints.purchaseMovie(),
        { userId, movieId: mediaId } // Include userId and mediaId in the request body
      );
      return { response: response.data };
    } catch (err) {
      return { err };
    }
  },
  
  // New method to check if the user has purchased the movie
  checkPurchaseStatus: async (userId, mediaId, paymentDetails) => {
    try {
      const response = await axios.post(
        baseURL + mediaEndpoints.checkPurchaseStatus(),
        { userId, mediaId , ...paymentDetails }
      );
      return { response: response.data };
    } catch (err) {
      return { err };
    }
  },

  // Add the superuser search API call
  searchSuperUsers: async ({ query, page }) => {
    try {
      const response = await axios.get(
        `http://thecameratales.com:5000/api/admin/search?query=${query}&page=${page}`
      );
      return { response: response.data, err: null };
    } catch (error) {
      return { response: null, err: error };
    }
  }
};

export default mediaApi;
