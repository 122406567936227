import React, { useState } from 'react';
import axios from 'axios';
import { toast } from "react-toastify";
import { Box, Button, TextField, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';

const VideoUploadForm = ({ open, onClose, onVideoUpload, user, loggedInUser }) => {
  const [videoFile, setVideoFile] = useState(null);
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [success, setSuccess] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file && file.type.startsWith('video/')) {
      setVideoFile(file); // Only accept video files
      setError(''); // Clear error if any
    } else {
      setError('Please select a valid video file.');
    }
  };

  const handleDescriptionChange = (e) => {
    setDescription(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!videoFile) {
      setError('Please select a video file');
      return;
    }
    if (!description) {
      setError('Please enter a description');
      return;
    }

    const formData = new FormData();
    formData.append('video', videoFile);
    formData.append('description', description);
    formData.append('userId', user);

    try {
      const response = await axios.post('http://thecameratales.com:5000/api/video/posts', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': localStorage.getItem('token'),
        },
      });
      onVideoUpload(response.data);
      setSuccess(true);
      setError('');
      onClose(); // Close the dialog after successful upload
      setVideoFile(null);
      setDescription('');
    } catch (error) {
      setError('Error uploading video. Please try again.');
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Upload Video</DialogTitle>
      <DialogContent>
        <TextField
          variant="outlined"
          label="Video Description"
          multiline
          rows={4}
          value={description}
          onChange={handleDescriptionChange}
          placeholder="Enter a description for your video..."
          error={Boolean(error && !videoFile)}
          helperText={error && !videoFile ? error : ''}
          fullWidth
        />
        <Box mt={2}>
          <input
            type="file"
            accept="video/*" // Restrict to video files only
            onChange={handleFileChange}
          />
        </Box>
        {error && videoFile && <p style={{ color: 'red' }}>{error}</p>}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSubmit} variant="contained" color="primary">
          Upload Video
        </Button>
        <Button onClick={onClose} color="inherit">
          Cancel
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VideoUploadForm;
