import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import SlideshowOutlinedIcon from "@mui/icons-material/SlideshowOutlined";
import LiveTvOutlinedIcon from "@mui/icons-material/LiveTvOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SearchOutlinedIcon from "@mui/icons-material/SearchOutlined";
import RateReviewOutlinedIcon from "@mui/icons-material/RateReviewOutlined";
import LockResetOutlinedIcon from "@mui/icons-material/LockResetOutlined";
import { Button } from "@mui/material";

const main = [
  {
    display: "home",
    path: "/",
    icon: <HomeOutlinedIcon />,
    state: "home"
  },
  {
    display: "movies",
    path: "/movie",
    icon: <SlideshowOutlinedIcon />,
    state: "movie"
  },

  {
    display: "friday talkies",
    path: "/fridaytalkies",//here
    icon: <SlideshowOutlinedIcon />,
    state: "movie"
  },

  {
    display: "Registration",
    path: "https://content-registration-camera-tales.vercel.app/",
    icon: <LiveTvOutlinedIcon />,
    state: "tv",
    isExternal: true,
  },


  // {
  //   display: "Audition Form",
  //   path: "/template/fill-details",
  //   icon: <LiveTvOutlinedIcon />,
  //   state: "tv"
  // },


  // Some comment explaining the purpose of this code



  // /template/fill-details
  // {
  //   display: "search",
  //   path: "/search",
  //   icon: <SearchOutlinedIcon />,
  //   state: "search"
  // }
];

const user = [
  {
    display: "favorites",
    path: "/favorites",
    icon: <FavoriteBorderOutlinedIcon />,
    state: "favorite"
  },
  {
    display: "reviews",
    path: "/reviews",
    icon: <RateReviewOutlinedIcon />,
    state: "reviews"
  },
  {
    display: "password update",
    path: "/password-update",
    icon: <LockResetOutlinedIcon />,
    state: "password.update"
  }
];

const menuConfigs = { main, user };

export default menuConfigs;