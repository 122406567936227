import { useEffect, useState } from "react";
import { Box, useMediaQuery, Dialog } from "@mui/material";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import SizzlersVid from '../components/SizzlersVideo/SizzlersVid';
import VideoUploadForm from "./VideoUploadForm";
import axios from 'axios';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { Navigation, Pagination } from 'swiper';
import AddIcon from '@mui/icons-material/Add';

const Circle = styled('div')(({ theme, profilePicture }) => ({
  width: "55px",
  height: "55px",
  borderRadius: "50%",
  backgroundColor: profilePicture ? `url(${profilePicture})` : "white",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "5px",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.up('sm')]: {
    width: "120px",
    height: "120px",
    margin: "6px"
  },
  "& img": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const UserSizzlers = ({ userId, user, loggedInUser }) => {
  const [medias, setMedias] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [modalOpen, setModalOpen] = useState(false); // State for video modal
  const [openUploadDialog, setOpenUploadDialog] = useState(false); // State for the upload dialog

  // Media queries to adjust the Swiper slides based on the screen size
  const isMediumScreen = useMediaQuery('(min-width: 1357px)');
  const isSmallScreen = useMediaQuery('(max-width: 1357px)');
  const isCustomScreen = useMediaQuery('(min-width: 1357px) and (max-width: 1357px)'); // Custom screen size check

  useEffect(() => {
    const getMedias = async () => {
      try {
        const response = await axios.get(`http://thecameratales.com:5000/api/video/user/${userId}/media`);
        if (response.status === 200) {
          setMedias(response.data);
        } else {
          toast.error("Failed to fetch media.");
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while fetching media.");
      }
    };

    getMedias();
  }, [userId]);

  const handleMediaClick = (media) => {
    setSelectedMedia(media);
    setModalOpen(true); // Open the video modal
  };

  const handleCloseModal = () => {
    setSelectedMedia(null);
    setModalOpen(false); // Close the video modal
  };

  const handleOpenUploadDialog = () => {
    setOpenUploadDialog(true); // Open the upload dialog
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false); // Close the upload dialog after video upload or cancellation
  };

  let slidesPerView = isCustomScreen ? 3 : isMediumScreen ? 5 : 3; // Show 3 slides for custom screen size, else default

  return (
    <>
      <div style={{ backgroundImage: `linear-gradient(to right, #15171a, #15171a, #15171a)`, backgroundSize: 'contain', paddingTop: '20px', paddingBottom: '2vh', border: '1px solid black', display: 'flex', justifyContent: 'center' }}>
        <Box sx={{ width: '100%', height: 'auto',}}>
          <Swiper
            slidesPerView={slidesPerView}
            spaceBetween={20} // Space between slides
            navigation // Add navigation arrows
            pagination={{ clickable: true }} // Optional pagination
            modules={[Navigation, Pagination]}
            // style={{ padding: 0 }} // Remove padding from the Swiper
          >

            {/* Render the user's profile picture */}
            {user && user.profilePicture && (
              <SwiperSlide>
                <Circle profilePicture={`http://thecameratales.com:5000/uploads/${user.profilePicture}`}>
                  {/* No need for img tag, background image is set via CSS */}
                </Circle>
              </SwiperSlide>
            )}


            {/* Video upload form circle as the first item */}
            <SwiperSlide>
              <Circle onClick={handleOpenUploadDialog}>
                <Box sx={{ textAlign: 'center', color: 'black', fontWeight: 'bold' }}>
                <AddIcon sx={{ color: 'black' }} /> {/* Centered plus icon */}
                </Box>
              </Circle>
            </SwiperSlide>
            

            {/* Render other sizzlers */}
            {medias.map((media, index) => (
              <SwiperSlide key={index}>
                <Circle onClick={() => handleMediaClick(media)}>
                <img src={`http://thecameratales.com:5000/uploads/${media?.userId?.profilePicture}`} alt={media.title} />
                </Circle>
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </div>

      {/* Modal for playing the selected video */}
      <Dialog
        open={modalOpen}
        onClose={handleCloseModal}
        fullScreen // Make the modal take the full screen
        slotProps={{
          backdrop: {
            sx: {
              backgroundColor: 'rgba(0, 0, 0, 0.8)', // Darken the backdrop
            }
          }
        }}
      >
        {/* Ensure the SizzlersVid component takes full width and height */}
        {selectedMedia && (
          <Box sx={{ width: '100%', height: '100%' }}>
            <SizzlersVid media={selectedMedia} />
          </Box>
        )}
      </Dialog>

      {/* Directly open the dialog inside VideoUploadForm */}
      <VideoUploadForm
        open={openUploadDialog}
        onClose={handleCloseUploadDialog}
        user={user}
        loggedInUser={loggedInUser}
        onVideoUpload={(newMedia) => {
          setMedias([newMedia, ...medias]); // Add the new media to the media list
          handleCloseUploadDialog(); // Close dialog after successful upload
        }}
      />
    </>
  );
};

export default UserSizzlers;
