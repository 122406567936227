import { useRef, useEffect, useState } from "react";
import { Box, Modal, useMediaQuery } from "@mui/material";
import SwiperCore, { Navigation, Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { toast } from "react-toastify";
import { styled } from '@mui/material/styles';
import sizzlerImage from '../../assets/testimage.jpg';
import SizzlersVid from '../SizzlersVideo/SizzlersVid';
import axios from "axios";

// Import Swiper styles
import 'swiper/swiper-bundle.min.css';

// Initialize Swiper core components
SwiperCore.use([Navigation, Pagination]);

const Circle = styled('div')(({ theme }) => ({
  width: "80px",
  height: "80px",
  borderRadius: "50%",
  backgroundColor: "gray",
  cursor: "pointer",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  margin: "5px",
  overflow: "hidden",
  position: "relative",
  [theme.breakpoints.up('sm')]: {
    width: "100px",
    height: "100px",
    margin: "5px"
  },
  "& img": {
    position: "absolute",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    objectFit: "cover",
  },
}));

const Sizzlers = () => {
  const [medias, setMedias] = useState([]);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [swiperIndex, setSwiperIndex] = useState(0);
  const [swiper, setSwiper] = useState(null); // Local state to hold Swiper instance

  const swiperRef = useRef(null); // Create a ref for Swiper

   // Media queries for screen sizes
   const isMediumScreen = useMediaQuery('(min-width: 801px) and (max-width: 1496px)');
   const isSmallScreen = useMediaQuery('(max-width: 800px)');
   const isSpecificScreenSize = useMediaQuery('(min-width: 1500px) and (max-width: 1600px)'); // Correct media query for 1500px wide screens

   const apiBaseURL = process.env.REACT_APP_API_BASE_URL;
   console.log("API Base URL:", apiBaseURL);


  useEffect(() => {
    const getMedias = async () => {
      try {
        const response = await axios.get(`${apiBaseURL}/api/video/posts`);
        if (response.status === 200) {
          setMedias(response.data);
        } else {
          toast.error("Failed to fetch media.");
        }
      } catch (error) {
        console.error(error);
        toast.error("An error occurred while fetching media.");
      }
    };

    getMedias();
  }, []);

  const handleMediaClick = (media, index) => {
    console.log("Selected index:", index); // Log the index
    setSelectedMedia(media);
    setSwiperIndex(index);
    setModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedMedia(null);
    setModalOpen(false);
  };

  useEffect(() => {
    if (swiper) {
      swiper.slideTo(swiperIndex, 0); // Slide to the index with no animation
    }
  }, [swiper, swiperIndex]);

 // Adjusting slidesPerView based on screen size
 let slidesPerView = 13; // Default for larger screens

 if (isSmallScreen) {
   slidesPerView = 3; // For small screens (800px or less)
 } else if (isSpecificScreenSize) {
   slidesPerView = 7; // For 1500px - 1600px wide screens
 } else if (isMediumScreen) {
   slidesPerView = 5; // For medium screens (801px - 1199px)
 }

  return (
    <>
      <div style={{ textAlign: 'Left', fontSize: '35px', fontWeight: 'bold', backgroundImage: "linear-gradient(to bottom, #15171a, #15171a, #15171a)", color: 'white', marginLeft:"1.8vw" }}><i>SIZZLERS...</i></div>
      <div style={{ backgroundImage: `linear-gradient(to bottom, #15171a, #15171a, #15171a)`, backgroundSize: 'contain', paddingTop: '20px', paddingBottom: '20px', border: 'none', marginLeft:"2vw" }}>
        <Modal style={{ border: 'none' }} open={modalOpen} onClose={handleCloseModal}>
          <Box sx={{ position: 'absolute', top: '0', left: '4vw', width: '100vw', height: '100vh', margin: 0, padding: 0, border: "none" }}>
            {selectedMedia && <SizzlersVid selectedMedia={selectedMedia} />}
          </Box>
        </Modal>

        <Swiper
          onSwiper={setSwiper} // Capture Swiper instance
          ref={swiperRef} // Attach ref to Swiper
          slidesPerView={slidesPerView}
          navigation
          spaceBetween={1}
        >
          {medias.map((media, index) => (
            <SwiperSlide key={index}>
              <Circle onClick={() => handleMediaClick(media, index)}>
                <img src={`${apiBaseURL}/uploads/${media.userId.profilePicture}`} alt={media.title} />
              </Circle>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </>
  );
};

export default Sizzlers;
