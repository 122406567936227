import { LoadingButton } from "@mui/lab";
import { Box, Button, Stack, TextField, Toolbar } from "@mui/material";
import { useState, useEffect, useCallback } from "react";
import { toast } from "react-toastify";
import mediaApi from "../api/modules/media.api";
import MediaGrid from "../components/common/MediaGrid";
import uiConfigs from "../configs/ui.configs";

const mediaTypes = ["movie", "webseries", "webshows", "musicvideos", "shortfilms", "tales", "people"];
let timer;
const timeout = 500;

const MediaSearch = () => {
  const [query, setQuery] = useState("");
  const [onSearch, setOnSearch] = useState(false);
  const [mediaType, setMediaType] = useState(mediaTypes[0]);
  const [medias, setMedias] = useState([]);
  const [page, setPage] = useState(1);

  const search = useCallback(async () => {
    setOnSearch(true);

    try {
      let response;
      let err;

      if (mediaType === "people") {
        // Search superusers when mediaType is 'people'
        ({ response, err } = await mediaApi.searchSuperUsers({ query, page }));
      } else {
        // Otherwise, perform a general media search
        ({ response, err } = await mediaApi.search({ mediaType, query, page }));
      }

      setOnSearch(false);

      if (err) {
        throw new Error("API request failed");
      }

      // Log the full response for debugging
      console.log("Full API Response:", response);

      const results = response?.data?.results || response?.results;
      console.log("API Results:", results);

      if (Array.isArray(results)) {
        if (page > 1) {
          setMedias((m) => [...m, ...results]);
        } else {
          setMedias([...results]);
        }
      } else {
        console.warn("Results are not an array or are undefined");
      }
    } catch (error) {
      setOnSearch(false);
      toast.error("An unexpected error occurred.");
      console.error("Search error:", error);
    }
  }, [mediaType, query, page]);

  useEffect(() => {
    if (query.trim().length === 0) {
      setMedias([]);
      setPage(1);
    } else search();
  }, [search, query, mediaType, page]);

  useEffect(() => {
    setMedias([]);
    setPage(1);
  }, [mediaType]);

  const onCategoryChange = (selectedCategory) => setMediaType(selectedCategory);

  const onQueryChange = (e) => {
    const newQuery = e.target.value;
    clearTimeout(timer);

    timer = setTimeout(() => {
      setQuery(newQuery);
    }, timeout);
  };

  return (
    <>
      <Toolbar />
      <Box sx={{ ...uiConfigs.style.mainContent, height: "80vh" }}>
        <Stack spacing={2}>
          <Stack spacing={2} direction="row" justifyContent="center" sx={{ width: "100%" }}>
            {mediaTypes.map((item, index) => (
              <Button
                size="large"
                key={index}
                variant={mediaType === item ? "contained" : "text"}
                sx={{
                  color: mediaType === item ? "primary.contrastText" : "primary.contrastText",
                }}
                onClick={() => onCategoryChange(item)}
              >
                {item}
              </Button>
            ))}
          </Stack>
          <TextField
            color="primary"
            placeholder="Search"
            sx={{
              width: "100%",
              input: { color: "white" }, // Text color
              "& .MuiInputBase-input::placeholder": { color: "white" }, // Placeholder color
              "& .MuiOutlinedInput-root": {
                "& fieldset": {
                  borderColor: "white", // Default border color
                },
                "&:hover fieldset": {
                  borderColor: "white", // Border color on hover
                },
                "&.Mui-focused fieldset": {
                  borderColor: "white", // Border color when focused
                },
              },
            }}
            autoFocus
            onChange={onQueryChange}
          />

          <MediaGrid medias={medias} mediaType={mediaType} />

          {medias.length > 0 && (
            <LoadingButton loading={onSearch} onClick={() => setPage(page + 1)}>
              load more
            </LoadingButton>
          )}
        </Stack>
      </Box>
    </>
  );
};

export default MediaSearch;
