import React, { useState } from 'react';
import { Modal, TextField, Button, Grid, MenuItem, Typography, Box, FormControl, InputLabel, Select, Tabs, Tab, Drawer, useMediaQuery } from '@mui/material';
import axios from 'axios';
import DatePicker from 'react-datepicker';
import { useFormik } from 'formik';
import 'react-datepicker/dist/react-datepicker.css';
import * as Yup from 'yup';
import CmsNavbar from './CmsNavbar'; // Import the Navbar component
import { useNavigate } from 'react-router-dom'; // UseNavigate from React Router v6

const CMSSuperUserForm = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const navigate = useNavigate(); // Updated to use navigate instead of history
    const [activeTab, setActiveTab] = useState(0);
    const [selectedDate, setSelectedDate] = useState(null);
    const [moviesAsLead, setMoviesAsLead] = useState([{ title: '', role1: '', link: '', production: '', thumbnail: '' }]);
    const [musicAlbums, setMusicAlbums] = useState([{ title: '', link: '', role1: '', thumbnail: '' }]);
    const [documentaries, setDocumentaries] = useState([{ title: '', platform: '', role1: '', production: '', thumbnail: '' }]);

    // Media query to check if the screen width is less than 545 pixels
    const isSmallScreen = useMediaQuery('(max-width: 545px)');

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: JSON.parse(localStorage.getItem('user'))?.email || '',
            password: '',
            biography: '',
            birthDate: null,
            // age: '',
            // height: '',
            nationality: '',
            gender: '',
            role: 'usertoapprove',
            // imdbProfile: '',
            // twitterHandle: '',
            // instagramHandle: '',
            knownFor: [''],
            awards: [{ name: '', year: '' }],
            contactInfo: {
                phone: '',
                address: {
                    street: '',
                    city: '',
                    state: '',
                    zip: '',
                    country: ''
                }
            },
            categories: [],
            moviesAsLead: [],
            musicAlbums: [{ title: '', link: '', role1: '', thumbnail: '' }],
            documentaries: [{ title: '', platform: '', role1: '', thumbnail: '' }],
        },
        validationSchema: Yup.object({
            // Add your validation schema here
        }),
        onSubmit: async (values) => {
            values.role = values.role || 'super-user';
            console.log('Form values:', values); // Log form values on submit

            try {
                // Send form data to the server
                const response = await axios.post('http://thecameratales.com:5000/api/admin/createsuperuser/request', values, {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                });

                // Handle success response
                console.log('Submit clicked');
                console.log('Server response:', response.data);

                // Show modal for 3 seconds and then log out
                setModalOpen(true);

                setTimeout(() => {
                    // Clear user data and navigate to login page or homepage
                    localStorage.removeItem('user'); // Remove user from localStorage
                    setModalOpen(false);
                    navigate('/login'); // Redirect to login page using navigate
                }, 3000); // Wait for 3 seconds before logging out

            } catch (error) {
                // Handle error response
                console.error('Error submitting the form:', error.response ? error.response.data : error.message);
            }
        },
    });


    // Log formik errors and touched fields
    console.log('Formik errors:', formik.errors);
    console.log('Formik touched fields:', formik.touched);

    // Add a new movie entry
    const addMovie = () => {
        setMoviesAsLead([...moviesAsLead, { title: '', role1: '', link: '', production: '', thumbnail: '' }]);
    };

    // Remove a movie entry
    const removeMovie = (index) => {
        const updatedMovies = moviesAsLead.filter((_, idx) => idx !== index);
        setMoviesAsLead(updatedMovies);
    };

    // Handle tab change
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };

    const handleNext = () => {
        if (activeTab < 4) { // Assuming there are 5 tabs (0-4)
            setActiveTab(activeTab + 1);
        }
    };

    const handlePrevious = () => {
        if (activeTab > 0) {
            setActiveTab(activeTab - 1);
        }
    };

    // Handlers for Music Albums
    const addMusicAlbum = () => {
        setMusicAlbums([...musicAlbums, { title: '', link: '', role1: '', thumbnail: '' }]);
    };

    const removeMusicAlbum = (index) => {
        const updatedAlbums = musicAlbums.filter((_, idx) => idx !== index);
        setMusicAlbums(updatedAlbums);
    };

    // Handlers for Documentaries
    const addDocumentary = () => {
        setDocumentaries([...documentaries, { title: '', platform: '', role1: '', production: '', thumbnail: '' }]);
    };

    const removeDocumentary = (index) => {
        const updatedDocs = documentaries.filter((_, idx) => idx !== index);
        setDocumentaries(updatedDocs);
    };

    // Movies As Lead handlers
    const handleMovieChange = (index, field, value) => {
        const updatedMovies = [...moviesAsLead];
        updatedMovies[index][field] = value;
        setMoviesAsLead(updatedMovies);
        formik.setFieldValue('moviesAsLead', updatedMovies);
    };

    // Music Albums handlers
    const handleMusicAlbumChange = (index, field, value) => {
        const updatedAlbums = [...musicAlbums];
        updatedAlbums[index][field] = value;
        setMusicAlbums(updatedAlbums);
        formik.setFieldValue('musicAlbums', updatedAlbums);
    };

    // Documentaries handlers
    const handleDocumentaryChange = (index, field, value) => {
        const updatedDocs = [...documentaries];
        updatedDocs[index][field] = value;
        setDocumentaries(updatedDocs);
        formik.setFieldValue('documentaries', updatedDocs);
    };

    return (
        <>
            <CmsNavbar />
            <Box sx={{ display: 'flex', mt: '8vh' }}>
                {!isSmallScreen && (
                    <Drawer
                        variant="permanent"
                        sx={{
                            width: 240,
                            flexShrink: 0,
                            marginRight: "1vw",
                            '& .MuiDrawer-paper': {
                                width: 240,
                                boxSizing: 'border-box',
                                background: 'black', // Dark blue-grey background
                                color: 'white',
                                padding: '20px',
                                marginTop: "8vh",
                                marginLeft: "3vw",
                                maxHeight: "35vh",
                                // borderRadius: '10px',
                                display: 'flex',
                                flexDirection: 'column',
                                zIndex: '1',
                                // Add media query for smaller screens
                                // '@media (max-width: 992px) and (max-height: 858px)': {
                                //     marginTop: '17.5vh',
                                // }
                            },
                        }}
                    >
                        <Box sx={{ flex: '1' }}>
                            <Typography variant="h6" sx={{ fontWeight: 'bold', marginBottom: '16px', color: '#F0F0F0' }}>
                                Form Sections
                            </Typography>
                            <Tabs
                                orientation="vertical"
                                value={activeTab}
                                onChange={handleTabChange}
                                sx={{ width: '100%' }}
                            >
                                <Tab
                                    label="Basic Info"
                                    sx={{ fontWeight: 'bold', color: '#F0F0F0', '&.Mui-selected': { color: '#FFD700' }, borderBottom: '1px solid #4F5B6A' }}
                                />
                                <Tab
                                    label="Contact Info"
                                    sx={{ fontWeight: 'bold', color: '#F0F0F0', '&.Mui-selected': { color: '#FFD700' }, borderBottom: '1px solid #4F5B6A' }}
                                />
                                <Tab
                                    label="Medias Worked On"
                                    sx={{ fontWeight: 'bold', color: '#F0F0F0', '&.Mui-selected': { color: '#FFD700' }, borderBottom: '1px solid #4F5B6A' }}
                                />
                                <Tab
                                    label="Music Albums"
                                    sx={{ fontWeight: 'bold', color: '#F0F0F0', '&.Mui-selected': { color: '#FFD700' }, borderBottom: '1px solid #4F5B6A' }}
                                />
                                <Tab
                                    label="Documentaries"
                                    sx={{ fontWeight: 'bold', color: '#F0F0F0', '&.Mui-selected': { color: '#FFD700' }, borderBottom: '1px solid #4F5B6A' }}
                                />
                            </Tabs>
                        </Box>
                    </Drawer>
                )}

                <Box sx={{ padding: '20px', background: 'white', marginTop: '0vh', color: 'black', fontWeight: 'bolder', marginRight: '3vw' }}>
                    <Typography variant="h3" sx={{ marginBottom: '20px', fontWeight: 'bolder' }}>Super User Access Form</Typography>
                    <form onSubmit={formik.handleSubmit}>
                        <Grid container spacing={2} sx={{ minHeight: '100vh' }}>
                            {activeTab === 0 && (
                                <>
                                    {/* First Name */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="First Name"
                                            name="firstName"
                                            fullWidth
                                            value={formik.values.firstName}
                                            onChange={formik.handleChange}
                                            error={formik.touched.firstName && Boolean(formik.errors.firstName)}
                                            helperText={formik.touched.firstName && formik.errors.firstName}
                                        />
                                    </Grid>

                                    {/* Last Name */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Last Name"
                                            name="lastName"
                                            fullWidth
                                            value={formik.values.lastName}
                                            onChange={formik.handleChange}
                                            error={formik.touched.lastName && Boolean(formik.errors.lastName)}
                                            helperText={formik.touched.lastName && formik.errors.lastName}
                                        />
                                    </Grid>

                                    {/* Categories */}
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel>Select Your Role/Roles</InputLabel>
                                            <Select
                                                multiple
                                                name="categories"
                                                value={formik.values.categories}
                                                onChange={(event) => formik.setFieldValue('categories', event.target.value)}
                                                renderValue={(selected) => (
                                                    <div>
                                                        {selected.join(', ')}
                                                    </div>
                                                )}
                                                error={formik.touched.categories && Boolean(formik.errors.categories)}
                                            >
                                                <MenuItem value="Actor">Actor</MenuItem>
                                                <MenuItem value="Actress">Actress</MenuItem>
                                                <MenuItem value="Producer">Producer</MenuItem>
                                                <MenuItem value="Co-Producer">Co-Producer</MenuItem>
                                                <MenuItem value="Execuitive Producer">Execuitive Producer</MenuItem>
                                                <MenuItem value="Director">Director</MenuItem>
                                                <MenuItem value="Assistant Director">Assistant Director</MenuItem>
                                                <MenuItem value="Associate Director">Associate Director</MenuItem>
                                                <MenuItem value="Action Director">Action Director</MenuItem>
                                                <MenuItem value="Lyrics Writer">Lyrics Writer</MenuItem>
                                                <MenuItem value="Writer">Writer</MenuItem>
                                                <MenuItem value="Cinematographer">Cinematographer</MenuItem>
                                                <MenuItem value="Singer">Singer</MenuItem>
                                                <MenuItem value="Musicians">Musicians</MenuItem>
                                                <MenuItem value="Music Director">Music Director</MenuItem>
                                                <MenuItem value="Music Composer">Music Composer</MenuItem>
                                                <MenuItem value="Editor/Colouriest">Editor/Colouriest</MenuItem>
                                                <MenuItem value="Executive Producer">Executive Producer</MenuItem>
                                                <MenuItem value="Line Producer">Line Producer</MenuItem>
                                                <MenuItem value="Production Manager">Production Manager</MenuItem>
                                                <MenuItem value="Make Up">Make Up</MenuItem>
                                                <MenuItem value="VFX Artist">VFX Artist</MenuItem>
                                                <MenuItem value="Art Director">Art Director</MenuItem>
                                                <MenuItem value="Costume Designer">Costume Designer</MenuItem>
                                                <MenuItem value="Production Houses">Production Houses</MenuItem>
                                                <MenuItem value="Editing Studio">Editing Studio</MenuItem>
                                                <MenuItem value="Music Studio">Music Studio</MenuItem>
                                                <MenuItem value="Dubbing Studio">Dubbing Studio</MenuItem>
                                                <MenuItem value="VFX Studio">VFX Studio</MenuItem>
                                                <MenuItem value="Stunt Man">Stunt Man</MenuItem>
                                                <MenuItem value="Spot Dada">Spot Dada</MenuItem>
                                                <MenuItem value="Lightman">Lightman</MenuItem>
                                            </Select>
                                            {formik.touched.categories && formik.errors.categories && (
                                                <Typography color="error" variant="body2">{formik.errors.categories}</Typography>
                                            )}
                                        </FormControl>
                                    </Grid>

                                    {/* Email
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Email"
                                            name="email"
                                            fullWidth
                                            value={formik.values.email}
                                            onChange={formik.handleChange}
                                            error={formik.touched.email && Boolean(formik.errors.email)}
                                            helperText={formik.touched.email && formik.errors.email}
                                        />
                                    </Grid> */}

                                    {/* Password */}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Password"
                                            name="password"
                                            type="password"
                                            fullWidth
                                            value={formik.values.password}
                                            onChange={formik.handleChange}
                                            error={formik.touched.password && Boolean(formik.errors.password)}
                                            helperText={formik.touched.password && formik.errors.password}
                                        />
                                    </Grid>

                                    {/* Nationality */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Nationality"
                                            name="nationality"
                                            fullWidth
                                            value={formik.values.nationality}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Age"
                                            name="age"
                                            fullWidth
                                            value={formik.values.age}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid> */}

                                    {/* <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Height"
                                            name="height"
                                            fullWidth
                                            value={formik.values.height}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid> */}

                                    {/* Gender */}
                                    <Grid item xs={12} sm={6}>
                                        <TextField
                                            label="Gender"
                                            name="gender"
                                            select
                                            fullWidth
                                            value={formik.values.gender}
                                            onChange={formik.handleChange}
                                        >
                                            <MenuItem value="male">Male</MenuItem>
                                            <MenuItem value="female">Female</MenuItem>
                                            <MenuItem value="other">Other</MenuItem>
                                        </TextField>
                                    </Grid>

                                    <Grid item xs={12} style={{ marginTop: "1vh", marginLeft: "1vh" }}>
                                        <Typography variant="h6">Date of Birth:</Typography>
                                        <DatePicker
                                            selected={selectedDate}
                                            onChange={(date) => {
                                                setSelectedDate(date);
                                                formik.setFieldValue('birthDate', date);
                                            }}
                                            dateFormat="yyyy-MM-dd" // Adjust the format as needed
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    fullWidth
                                                    variant="outlined"
                                                    placeholder="Select date" // Placeholder text
                                                    InputProps={{
                                                        ...params.InputProps,
                                                        style: { borderRadius: 50, backgroundColor: '#F5DEB3' }, // Rounded corners and custom background color
                                                    }}
                                                    InputLabelProps={{
                                                        style: { color: '#555555' }, // Label color
                                                    }}
                                                    sx={{
                                                        width: '100%', // Full width
                                                        maxWidth: '500px', // Adjust to your desired max width
                                                        '& .MuiOutlinedInput-root': {
                                                            borderRadius: 8,
                                                            backgroundColor: '#ffffff', // White background for the input
                                                        },
                                                        '& .MuiInputLabel-root': {
                                                            color: '#555555', // Label color
                                                        },
                                                        '& .MuiOutlinedInput-input': {
                                                            padding: '10px 14px', // Padding for the input text
                                                        },
                                                        '& .MuiFormHelperText-root': {
                                                            color: '#d32f2f', // Error text color
                                                        },
                                                    }}
                                                    error={formik.touched.birthDate && Boolean(formik.errors.birthDate)}
                                                    helperText={formik.touched.birthDate && formik.errors.birthDate}
                                                />
                                            )}
                                        />
                                    </Grid>

                                    {/* Biography */}
                                    <Grid item xs={12}>
                                        <TextField
                                            label="Biography"
                                            name="biography"
                                            fullWidth
                                            multiline
                                            minRows={4}
                                            value={formik.values.biography}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid>

                                    {/* Social Links */}
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            label="IMDB Profile"
                                            name="imdbProfile"
                                            fullWidth
                                            value={formik.values.imdbProfile}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            label="Twitter Handle"
                                            name="twitterHandle"
                                            fullWidth
                                            value={formik.values.twitterHandle}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid> */}
                                    {/* <Grid item xs={12}>
                                        <TextField
                                            label="Instagram Handle"
                                            name="instagramHandle"
                                            fullWidth
                                            value={formik.values.instagramHandle}
                                            onChange={formik.handleChange}
                                        />
                                    </Grid> */}

                                    {/* Known For */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Known For</Typography>
                                        {formik.values.knownFor.map((item, index) => (
                                            <Box key={index} sx={{ marginBottom: '16px' }}>
                                                <TextField
                                                    label="Known For"
                                                    name={`knownFor[${index}]`}
                                                    fullWidth
                                                    value={item}
                                                    onChange={formik.handleChange}
                                                />
                                            </Box>
                                        ))}
                                    </Grid>

                                    {/* Awards */}
                                    <Grid item xs={12}>
                                        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Awards</Typography>
                                        {formik.values.awards.map((award, index) => (
                                            <Box key={index} sx={{ marginBottom: '16px' }}>
                                                <TextField
                                                    label="Award Name"
                                                    name={`awards[${index}].name`}
                                                    fullWidth
                                                    value={award.name}
                                                    onChange={formik.handleChange}
                                                />
                                                <TextField
                                                    label="Year"
                                                    name={`awards[${index}].year`}
                                                    fullWidth
                                                    value={award.year}
                                                    onChange={formik.handleChange}
                                                />
                                                <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto', paddingTop: '16px' }}>
                                                    <Button
                                                        variant="outlined"
                                                        color="inherit"
                                                        disabled={activeTab === 0}
                                                        onClick={handlePrevious}
                                                    >
                                                        Previous
                                                    </Button>
                                                    <Button
                                                        variant="contained"
                                                        color="primary"
                                                        disabled={activeTab === 4} // Assuming there are 5 tabs (0-4)
                                                        onClick={handleNext}
                                                    >
                                                        Next
                                                    </Button>
                                                </Box>
                                            </Box>
                                        ))}
                                    </Grid>
                                </>
                            )}



                            {activeTab === 1 && (
                                <>
                                    {/* Contact Info */}
                                    < Grid item xs={12} sx={{ minHeight: '100vh' }}>
                                        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Contact Info</Typography>
                                        <TextField
                                            label="Phone"
                                            name="contactInfo.phone"
                                            fullWidth
                                            value={formik.values.contactInfo.phone}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            label="Street"
                                            name="contactInfo.address.street"
                                            fullWidth
                                            value={formik.values.contactInfo.address.street}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            label="City"
                                            name="contactInfo.address.city"
                                            fullWidth
                                            value={formik.values.contactInfo.address.city}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            label="State"
                                            name="contactInfo.address.state"
                                            fullWidth
                                            value={formik.values.contactInfo.address.state}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            label="ZIP"
                                            name="contactInfo.address.zip"
                                            fullWidth
                                            value={formik.values.contactInfo.address.zip}
                                            onChange={formik.handleChange}
                                        />
                                        <TextField
                                            label="Country"
                                            name="contactInfo.address.country"
                                            fullWidth
                                            value={formik.values.contactInfo.address.country}
                                            onChange={formik.handleChange}
                                        />
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto', paddingTop: '16px' }}>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                disabled={activeTab === 0}
                                                onClick={handlePrevious}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={activeTab === 4} // Assuming there are 5 tabs (0-4)
                                                onClick={handleNext}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                            )}

                            {activeTab === 2 && (
                                <>
                                    {/* Movies As Lead */}
                                    <Grid item xs={12} sx={{ minHeight: '100vh' }}>
                                        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Media worked on (movies, webseries, etc.)</Typography>
                                        {moviesAsLead.map((movie, index) => (
                                            <Box key={index} sx={{ marginBottom: '16px' }}>
                                                <TextField
                                                    label="Title"
                                                    name={`moviesAsLead[${index}].title`}
                                                    fullWidth
                                                    value={movie.title}
                                                    onChange={(e) => handleMovieChange(index, 'title', e.target.value)}
                                                />
                                                <TextField
                                                    label="Role"
                                                    name={`moviesAsLead[${index}].role1`}
                                                    fullWidth
                                                    value={movie.role1}
                                                    onChange={(e) => handleMovieChange(index, 'role1', e.target.value)}
                                                />
                                                <TextField
                                                    label="Link"
                                                    name={`moviesAsLead[${index}].link`}
                                                    fullWidth
                                                    value={movie.link}
                                                    onChange={(e) => handleMovieChange(index, 'link', e.target.value)}
                                                />
                                                <TextField
                                                    label="Production"
                                                    name={`moviesAsLead[${index}].production`}
                                                    fullWidth
                                                    value={movie.production}
                                                    onChange={(e) => handleMovieChange(index, 'production', e.target.value)}
                                                />
                                                <TextField
                                                    label="Thumbnail"
                                                    name={`moviesAsLead[${index}].thumbnail`}
                                                    fullWidth
                                                    value={movie.thumbnail}
                                                    onChange={(e) => handleMovieChange(index, 'thumbnail', e.target.value)}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    sx={{ marginTop: '8px' }}
                                                    onClick={() => removeMovie(index)}
                                                >
                                                    Remove Movie
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            variant="contained"
                                            onClick={addMovie}
                                            sx={{ marginTop: '16px' }}
                                        >
                                            Add Movie
                                        </Button>

                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto', paddingTop: '16px' }}>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                disabled={activeTab === 0}
                                                onClick={handlePrevious}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={activeTab === 4} // Assuming there are 5 tabs (0-4)
                                                onClick={handleNext}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                            )}


                            {/* Music Albums */}
                            {activeTab === 3 && (
                                <>
                                    <Grid item xs={12} sx={{ minHeight: '100vh' }}>
                                        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Music Albums</Typography>
                                        {musicAlbums.map((album, index) => (
                                            <Box key={index} sx={{ marginBottom: '16px' }}>
                                                <TextField
                                                    label="Title"
                                                    name={`musicAlbums[${index}].title`}
                                                    fullWidth
                                                    value={album.title}
                                                    onChange={(e) => handleMusicAlbumChange(index, 'title', e.target.value)}
                                                />
                                                <TextField
                                                    label="Link"
                                                    name={`musicAlbums[${index}].link`}
                                                    fullWidth
                                                    value={album.link}
                                                    onChange={(e) => handleMusicAlbumChange(index, 'link', e.target.value)}
                                                />
                                                <TextField
                                                    label="Role"
                                                    name={`musicAlbums[${index}].role1`}
                                                    fullWidth
                                                    value={album.role1}
                                                    onChange={(e) => handleMusicAlbumChange(index, 'role1', e.target.value)}
                                                />
                                                <TextField
                                                    label="Thumbnail"
                                                    name={`musicAlbums[${index}].thumbnail`}
                                                    fullWidth
                                                    value={album.thumbnail}
                                                    onChange={(e) => handleMusicAlbumChange(index, 'thumbnail', e.target.value)}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    sx={{ marginTop: '8px' }}
                                                    onClick={() => removeMusicAlbum(index)}
                                                >
                                                    Remove Album
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            variant="contained"
                                            onClick={addMusicAlbum}
                                            sx={{ marginTop: '16px' }}
                                        >
                                            Add Music Album
                                        </Button>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 'auto', paddingTop: '16px' }}>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                disabled={activeTab === 0}
                                                onClick={handlePrevious}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={activeTab === 4} // Assuming there are 5 tabs (0-4)
                                                onClick={handleNext}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                    </Grid>
                                </>
                            )}

                            {activeTab === 4 && (
                                <>
                                    <Grid item xs={12} sx={{ minHeight: '100vh' }}>
                                        <Typography variant="h6" style={{ fontWeight: 'bolder' }}>Documentaries</Typography>
                                        {documentaries.map((doc, index) => (
                                            <Box key={index} sx={{ marginBottom: '16px' }}>
                                                <TextField
                                                    label="Title"
                                                    name={`documentaries[${index}].title`}
                                                    fullWidth
                                                    value={doc.title}
                                                    onChange={(e) => handleDocumentaryChange(index, 'title', e.target.value)}
                                                />
                                                <TextField
                                                    label="Platform"
                                                    name={`documentaries[${index}].platform`}
                                                    fullWidth
                                                    value={doc.platform}
                                                    onChange={(e) => handleDocumentaryChange(index, 'platform', e.target.value)}
                                                />
                                                <TextField
                                                    label="Role"
                                                    name={`documentaries[${index}].role1`}
                                                    fullWidth
                                                    value={doc.role1}
                                                    onChange={(e) => handleDocumentaryChange(index, 'role1', e.target.value)}
                                                />
                                                <TextField
                                                    label="Production"
                                                    name={`documentaries[${index}].production`}
                                                    fullWidth
                                                    value={doc.production}
                                                    onChange={(e) => handleDocumentaryChange(index, 'production', e.target.value)}
                                                />
                                                <TextField
                                                    label="Thumbnail"
                                                    name={`documentaries[${index}].thumbnail`}
                                                    fullWidth
                                                    value={doc.thumbnail}
                                                    onChange={(e) => handleDocumentaryChange(index, 'thumbnail', e.target.value)}
                                                />
                                                <Button
                                                    variant="outlined"
                                                    color="error"
                                                    sx={{ marginTop: '8px' }}
                                                    onClick={() => removeDocumentary(index)}
                                                >
                                                    Remove Documentary
                                                </Button>
                                            </Box>
                                        ))}
                                        <Button
                                            variant="contained"
                                            onClick={addDocumentary}
                                            sx={{ marginTop: '16px' }}
                                        >
                                            Add Documentary
                                        </Button>
                                        <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '3vw', paddingTop: '16px' }}>
                                            <Button
                                                variant="outlined"
                                                color="inherit"
                                                disabled={activeTab === 0}
                                                onClick={handlePrevious}
                                            >
                                                Previous
                                            </Button>
                                            <Button
                                                variant="contained"
                                                color="primary"
                                                disabled={activeTab === 4} // Assuming there are 5 tabs (0-4)
                                                onClick={handleNext}
                                            >
                                                Next
                                            </Button>
                                        </Box>
                                        {/* Submit Button */}
                                        <Grid item xs={12}>
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                color="primary"
                                                style={{ marginTop: "5vh" }}
                                            >
                                                Submit
                                            </Button>
                                        </Grid>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                        {/* Modal to thank the user */}
                        <Modal
                            open={modalOpen}
                            onClose={() => setModalOpen(false)}
                            aria-labelledby="modal-title"
                            aria-describedby="modal-description"
                        >
                            <Box
                                sx={{
                                    position: 'absolute',
                                    top: '50%',
                                    left: '50%',
                                    transform: 'translate(-50%, -50%)',
                                    width: 400,
                                    bgcolor: 'background.paper',
                                    borderRadius: '10px',
                                    boxShadow: 24,
                                    p: 4,
                                    textAlign: 'center'
                                }}
                            >
                                <Typography id="modal-title" variant="h6" component="h2" sx={{ color: '#1976d2', fontWeight: 'bold' }}>
                                    Thank You!
                                </Typography>
                                <Typography id="modal-description" sx={{ mt: 2, color: 'text.secondary' }}>
                                Thank You! Information Submitted Successfully!  Please log back in again!  Auto Logging out.... 
                                </Typography>
                            </Box>
                        </Modal>

                    </form>
                </Box>
            </Box >
        </>
    );
};

export default CMSSuperUserForm;
