/* global Razorpay */
import { Box, Button, CircularProgress, Divider, Grid, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { LoadingButton } from "@mui/lab";
import FavoriteIcon from "@mui/icons-material/Favorite";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";

import CircularRate from "../components/common/CircularRate";
import Container from "../components/common/Container";
import ImageHeader from "../components/common/ImageHeader";

import uiConfigs from "../configs/ui.configs";
import tmdbConfigs from "../api/configs/tmdb.configs";
import mediaApi from "../api/modules/media.api";
import { addFavorite, removeFavorite, getFavoriteStatus } from "../api/modules/favorite.api";

import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper";
import "swiper/swiper-bundle.min.css";

const MediaDetail = () => {
  const { mediaType, mediaId } = useParams();
  const navigate = useNavigate();

  const [media, setMedia] = useState();
  const [isFavorite, setIsFavorite] = useState(false);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('user')));
  const [isPurchased, setIsPurchased] = useState(false); // State to track if the movie is purchased

  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const getMedia = async () => {
      let response, err;
  
      if (mediaType === "favorites") {
        // Fetch favorite media items
        if (user) {
          const { response: favoritesResponse, err: favoritesErr } = await mediaApi.getFavorites(user.id);
          if (favoritesResponse) {
            setMedia(favoritesResponse); // Set media to the list of favorite items
            setLoading(false);
          }
          if (favoritesErr) {
            toast.error(favoritesErr.message);
            setLoading(false);
          }
        } else {
          toast.error("You need to be logged in to view favorites");
          setLoading(false);
        }
      } else {
        // Fetch media details
        const mediaResponse = await mediaApi.getDetail({ mediaType, mediaId });
        response = mediaResponse.response;
        err = mediaResponse.err;
  
        if (response) {
          setMedia(response);
          setLoading(false);
  
          // Check if the media item is in favorites
          if (user) {
            const { response: favoriteResponse } = await getFavoriteStatus(user.id, mediaId);
            if (favoriteResponse) {
              setIsFavorite(favoriteResponse.isFavorite);
            }
          }
        }
  
        if (err) {
          toast.error(err.message);
          setLoading(false);
        }
      }
    };
  
    getMedia();
  }, [mediaType, mediaId, user]);

  // useEffect(() => {
  //   const script = document.createElement('script');
  //   script.src = 'https://checkout.razorpay.com/v1/checkout.js';
  //   script.async = true;
  //   document.body.appendChild(script);

  //   return () => {
  //     document.body.removeChild(script);
  //   };
  // }, []);
  


  const handleFavorite = async () => {
    if (!user) {
      toast.error("You need to be logged in to add to favorites");
      return;
    }

    setLoading(true);
    const { response, err } = isFavorite
      ? await removeFavorite(user.id, mediaId)
      : await addFavorite(user.id, mediaId);

    if (response) {
      setIsFavorite(!isFavorite);
      toast.success(response.message);
    }

    if (err) {
      toast.error(err.message);
    }

    setLoading(false);
  };

  const handleCastClick = (castId) => {
    navigate(`/cast/${castId}`);
  };

  const handleWatchNow = () => {
    const url = `/${mediaType}/${mediaId}/video`;
    console.log('Navigating to:', url);
    window.location.href = url;  // This will navigate and reload the page
};

  const handlePurchase = async () => {
    if (!user) {
      toast.error("You need to be logged in to purchase this movie");
      return;
    }
  
    try {
      const { response, err } = await mediaApi.purchaseMovie(user.id, mediaId);
  
      if (err) {
        toast.error(err.message);
        return;
      }
  
      if (response) {
        const { orderId, amount, currency } = response;
  
        // Check if Razorpay is available
        if (typeof Razorpay === "undefined") {
          toast.error("Razorpay SDK is not loaded. Please try again later.");
          return;
        }
        console.log(typeof Razorpay); 
  
        const options = {
          key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Your Razorpay Key ID
          amount: amount, // Amount in the smallest currency unit (paise)
          currency: currency,
          name: "The Camera Tales",
          description: "Movie Purchase",
          order_id: orderId,
          handler: async (paymentResponse) => {
            try {
              const { razorpay_payment_id, razorpay_order_id, razorpay_signature } = paymentResponse;
              const verificationResponse = await mediaApi.checkPurchaseStatus(user.id, mediaId, {
                razorpay_payment_id,
                razorpay_order_id,
                razorpay_signature
              });
  
              if (verificationResponse.err) {
                toast.error("Payment verification failed. Please contact support.");
              } else {
                toast.success("Payment successful! Movie purchased.");
                setIsPurchased(true); // Update the purchase status
              }
            } catch (error) {
              toast.error("Error verifying payment. Please try again.");
            }
          },
          prefill: {
            name: user.name,
            email: user.email,
            contact: user.contactInfo || 9999999999,
          },
          notes: {
            address: "address",
          },
          theme: {
            color: "#3399cc",
          },
        };
  
        const rzp = new Razorpay(options);
        rzp.open();
      }
    } catch (error) {
      toast.error("Something went wrong. Please try again.");
    }
  };


  return (
    loading ? (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    ) : (
      media && (
        <>
          <ImageHeader imgPath={tmdbConfigs.backdropPath(media.backdrop_path || media.poster_path)} />
          {/* <Box sx={{  }}> */}
          <Container>
            <Grid container spacing={1}>
              <Grid item xs={12} md={4}>
                <Box sx={{
                  paddingTop: "140%",
                  ...uiConfigs.style.backgroundImage(tmdbConfigs.posterPath(media.poster_path || media.backdrop_path)),
                  borderRadius: '10px',
                  border: '2px solid black',
                  // width:"auto"
                  marginRight: '20px'
                  // marginLeft: '-3vw'
                }} />
              </Grid>
              <Grid item xs={12} md={8}>
                <Stack spacing={4}>
                  <Typography variant="h4" fontWeight="700"
                    sx={{
                      color: "transparent",
                      backgroundImage: "linear-gradient(to right, #F7971E 0%, #FFD200  51%, #F7971E  100%)",
                      WebkitBackgroundClip: "text"
                    }}>
                    {`${media.title || media.name} (${mediaType === tmdbConfigs.mediaType[mediaType] ? (media.release_date ? media.release_date.split("-")[0] : 'N/A') : (media.first_air_date ? media.first_air_date.split("-")[0] : 'N/A')})`}
                  </Typography>

                  <Stack direction="row" alignItems="center">
                    <CircularRate value={media.vote_average} />
                    <Divider orientation="vertical" />
                  </Stack>

                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      width: "98%",
                      height: "50vh",
                      overflow: "auto",
                      textAlign: "justify",
                      backgroundColor: "rgba(0, 0, 0, 0.5)",
                      color: "white",
                      padding: "20px"
                    }}
                  >
                    <Typography variant="body1"
                      sx={{
                        fontSize: { xs: "16px", sm: "20px", md: "24px", lg: "28px", xl: "32px" },
                        fontWeight: "bolder"
                      }}
                    >
                      <i>{media.overview}</i>
                    </Typography>
                  </Box>

                  <Stack direction="row" spacing={2}>
                    <LoadingButton
                      variant="text"
                      startIcon={isFavorite ? <FavoriteIcon /> : <FavoriteBorderOutlinedIcon />}
                      loadingPosition="start"
                      loading={loading}
                      onClick={handleFavorite}
                      sx={{ color: "white" }} // Set text color to white
                    >
                      {isFavorite ? 'Remove from Favorites' : 'Add to Favorites'}
                    </LoadingButton>
                    {mediaType === "fridaytalkies" && !isPurchased && (
                    <Button variant="contained" onClick={handlePurchase}>
                      Purchase Movie
                    </Button>
                  )}
                    <Button
                      variant="contained"
                      sx={{backgroundImage: "linear-gradient(to right, #780206, #061161)",}}
                      startIcon={<PlayArrowIcon />}
                      onClick={handleWatchNow}
                    >
                      Watch Now
                    </Button>
                  </Stack>
                  {/*  */}

                  <Box>
                    <Typography variant="h5" sx={{ color: "white", marginBottom: "20px" }}>Casts</Typography>
                    <Swiper
                      spaceBetween={10}
                      slidesPerView={5}
                      breakpoints={{
                        0: { slidesPerView: 3 }, // Extra small screens (xs)
                        600: { slidesPerView: 3 }, // Small screens (sm)
                        960: { slidesPerView: 4 }, // Medium screens (md)
                        1280: { slidesPerView: 5 }, // Large screens (lg) and up
                      }}
                      navigation={true}
                      pagination={{ clickable: true }}
                      modules={[Navigation, Pagination]}
                      style={{ width: "100%", height: "max-content" }}
                    >
                      {media.casts.map((cast) => (
                        <SwiperSlide key={cast._id}>
                          <Box sx={{ textAlign: "center" }} onClick={() => handleCastClick(cast._id)}>
                            <img
                              src={`${apiBaseURL}/uploads/${cast.profilePicture}`}
                              alt={`${cast.firstName} ${cast.lastName}`}
                              style={{ width: "100px", height: "100px", borderRadius: "50%", cursor: "pointer" }} // Added cursor: pointer
                            />
                            <Typography
                              variant="body2"
                              sx={{ marginTop: "5px", color: "white" }}
                            >
                              {cast.firstName} {cast.lastName}
                            </Typography>
                          </Box>
                        </SwiperSlide>
                      ))}
                    </Swiper>
                  </Box>
                </Stack>
              </Grid>
            </Grid>
          </Container>
          {/* </Box> */}
        </>
      )
    )
  );
};

export default MediaDetail;
