import React, { useRef, useState, useEffect } from "react";
import Ticker from "react-ticker";
import { Avatar } from "@material-ui/core";
import { ArrowBack, MoreVert, ThumbUp, ThumbDown, InsertComment, NearMe } from "@material-ui/icons";
import "./css/Video.css";
import { useSwipeable } from "react-swipeable"; // Import useSwipeable

function Videos({
  id,
  src,
  channel,
  description,
  initialLikes,
  initialDislikes,
  initialShares,
  initialComments,
  onLike,
  onDislike,
  onShare,
  onComment,
  isPlaying,
}) {
  const [playing, setPlaying] = useState(isPlaying);
  const [subs, setSubs] = useState(false);
  const [likes, setLikes] = useState(initialLikes || 0);
  const [dislikes, setDislikes] = useState(initialDislikes || 0);
  const [shares, setShares] = useState(initialShares || 0);
  const [comments, setComments] = useState(initialComments || []);
  const [newComment, setNewComment] = useState("");

  const videoRef = useRef(null);

  useEffect(() => {
    if (isPlaying) {
      videoRef.current.play();
    } else {
      videoRef.current.pause();
    }
  }, [isPlaying]);

  useEffect(() => {
    setLikes(initialLikes);
    setDislikes(initialDislikes);
    setShares(initialShares);
    setComments(initialComments);
  }, [initialLikes, initialDislikes, initialShares, initialComments]);

  const handleVideoPress = () => {
    setPlaying((prev) => !prev);
    if (playing) {
      videoRef.current.pause();
    } else {
      videoRef.current.play();
    }
  };

  const handleSubscribe = () => {
    setSubs((prev) => !prev);
  };

  const handleNewCommentChange = (event) => {
    setNewComment(event.target.value);
  };

  const handleNewCommentSubmit = async () => {
    if (newComment.trim() === "") return;
    try {
      await onComment(newComment);
      setNewComment("");
    } catch (error) {
      console.error("Error submitting comment:", error);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => console.log("Swiped left"), // Handle left swipe event
    onSwipedRight: () => console.log("Swiped right"), // Handle right swipe event
    preventDefaultTouchmoveEvent: true,
    trackMouse: true,
  });

  return (
    <div className="video" {...handlers}>
      <video
        id={id}
        className="video__player"
        onClick={handleVideoPress}
        loop
        ref={videoRef}
        src={src}
      />

      <div className="shortsContainer">
        <div className="shortsVideoTop">
          <div className="shortsVideoTopIcon">
            <ArrowBack style={{ fontSize: "3rem" }} />
          </div>
          <div className="shortsVideoTopIcon">
            <MoreVert style={{ fontSize: "3rem" }} />
          </div>
        </div>
        <div className="shortsVideoSideIcons">
          <div className="shortsVideoSideIcon" onClick={onLike}>
            <ThumbUp style={{ fontSize: "3rem", cursor: "pointer" }} />
            <p>{likes}</p>
          </div>
          <div className="shortsVideoSideIcon" onClick={onDislike}>
            <ThumbDown style={{ fontSize: "3rem", cursor: "pointer" }} />
            <p>{dislikes}</p>
          </div>
          <div className="shortsVideoSideIcon">
            <InsertComment style={{ fontSize: "3rem", cursor: "pointer" }} />
            <p>{comments.length}</p>
          </div>
          <div className="shortsVideoSideIcon" onClick={onShare}>
            <NearMe style={{ fontSize: "3rem", cursor: "pointer" }} />
            <p>{shares}</p>
          </div>
        </div>
        <div className="shortsBottom">
          <div className="shortsDesc">
            <Ticker mode="smooth">
              {({ index }) => (
                <>
                  <p className="description">{description}</p>
                </>
              )}
            </Ticker>
          </div>
          <div className="shortDetails">
            <Avatar src={"https://i.imgur.com/6VBx3io.png"} />
            <p>{channel}</p>
            <button onClick={handleSubscribe}>{subs ? "Subscribed" : "Subscribe"}</button>
          </div>
          <div className="commentsSection">
            {comments.map((comment, index) => (
              <div key={index} className="comment">
                <Avatar src={"https://i.imgur.com/6VBx3io.png"} />
                <p><strong>{comment.commenterName}</strong>: {comment.commentText}</p>
              </div>
            ))}
          </div>
          <div className="newCommentSection">
            <input
              type="text"
              value={newComment}
              onChange={handleNewCommentChange}
              placeholder="Add a comment..."
            />
            <button onClick={handleNewCommentSubmit}>Comment</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Videos;
