import React, { useState, useEffect } from 'react';
import { Box, Card, CardContent, CardActions, IconButton, Typography, Avatar, InputBase, Divider, List, ListItem, ListItemAvatar, ListItemText } from '@mui/material';
import FavoriteIcon from '@mui/icons-material/Favorite';
import ShareIcon from '@mui/icons-material/Share';
import DeleteIcon from '@mui/icons-material/Delete';
import axios from 'axios';
import { useParams } from 'react-router-dom';

const Post = ({ posts, onLike, onShare, setPosts }) => {
  const [user, setUser] = useState(null);
  const { userId } = useParams();

  // Get logged-in user data from local storage
  const loggedInUser = JSON.parse(localStorage.getItem('user'));
  console.log('Logged In User:', loggedInUser); // Log the logged-in user



  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`http://thecameratales.com:5000/api/admin/users/${userId}`);
        setUser(response.data);
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };
    fetchUser();
  }, [userId]);

  const handleLike = (postId) => {
    onLike(postId);
  };

  const handleShare = (postId) => {
    onShare(postId);
  };

  const handleDelete = async (postId) => {
    try {
      await axios.delete(`http://thecameratales.com:5000/api/admin/posts/${postId}`, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      // Update the posts state to remove the deleted post
      setPosts((prevPosts) => prevPosts.filter((post) => post._id !== postId));
    } catch (error) {
      console.error('Error deleting post:', error);
    }
  };

  const handleComment = async (postId, text) => {
    try {
      await axios.post(`http://thecameratales.com:5000/api/admin/posts/${postId}/comment`, { text }, {
        headers: {
          'x-auth-token': localStorage.getItem('token')
        }
      });
      // Optionally fetch updated posts or handle state update
    } catch (error) {
      console.error('Error adding comment:', error);
    }
  };

  return (
    <Box sx={{ mt: 5, backgroundColor: "rgba(0,0,0,0.9)" }}>
      {posts.map((post, index) => (
        <Card key={post._id || index} sx={{ mb: 3, background: "linear-gradient(to right, #15171a, #15171a, #15171a)" }}>
          <CardContent>
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
              <Avatar
                src={user?.profilePicture ? `http://thecameratales.com:5000/uploads/${user.profilePicture}` : ''}
                alt="User"
                sx={{ width: 30, height: 30, mr: 2 }}
              />
              <Typography variant="h6" sx={{ color: "#D3D3D3" }}>
                {user ? `${user.firstName} ${user.lastName}` : 'Unknown'}
              </Typography>
            </Box>
            {post.mediaType === 'image' ? (
              <img
                src={`http://thecameratales.com:5000/uploads/${post.mediaPath}`}
                alt={post.caption}
                style={{ width: '100%', borderRadius: 4, marginBottom: 16 }}
              />
            ) : (
              <video
                src={`http://thecameratales.com:5000/uploads/${post.mediaPath}`}
                controls
                controlsList="nodownload"  // This removes the download button
                style={{ width: '100%', borderRadius: 4, marginBottom: 16 }}
              />
            )}
            <Typography variant="body2" sx={{ mb: 1, color: "#D3D3D3" }}>{post.caption}</Typography>
            <Typography variant="body2" color="#D3D3D3">{post.likes?.length || 0} likes</Typography>
          </CardContent>
          <CardActions disableSpacing>
            <IconButton onClick={() => handleLike(post._id)} aria-label="like">
              <FavoriteIcon color="error" />
            </IconButton>
            <IconButton onClick={() => handleShare(post._id)} aria-label="share">
              <ShareIcon color="#D3D3D3" />
            </IconButton>
            {/* Log both IDs for debugging */}
            {/* Log both IDs for debugging */}
            {/* {console.log('Logged In User ID:', loggedInUser?.id, 'Post Created By:', post.createdBy?._id || 'Not Available')} */}
            {loggedInUser && loggedInUser.id === post.createdBy?._id && (// Conditional rendering of delete icon
              <IconButton onClick={() => handleDelete(post._id)} aria-label="delete">
                <DeleteIcon sx={{ color: '#D3D3D3' }} />
              </IconButton>
            )}
          </CardActions>
          <Box sx={{ px: 2, pb: 2 }}>
            <Divider />
            <List sx={{ maxHeight: 200, overflow: 'auto', paddingTop: 0 }}>
              {(post.comments || []).map((comment, index) => (
                <ListItem key={index} alignItems="flex-start">
                  <ListItemAvatar>
                    <Avatar alt={`${comment.user?.firstName || 'Unknown'} ${comment.user?.lastName || ''}`} src={`http://thecameratales.com:5000/uploads/${comment.user?.profilePicture || ''}`} />
                  </ListItemAvatar>
                  <ListItemText sx={{ color: "#D3D3D3", fontWeight: "bolder" }}
                    primary={`${comment.user?.firstName || 'Unknown'} ${comment.user?.lastName || ''}`}
                    secondary={comment.text}
                    secondaryTypographyProps={{
                      sx: { color: "#A9A9A9" } // Light grey color for the comment text
                    }}
                  />
                </ListItem>
              ))}
            </List>
            <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
              <InputBase
                placeholder="Add a comment"
                fullWidth
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && e.target.value.trim() !== '') {
                    handleComment(post._id, e.target.value);
                    e.target.value = '';
                  }
                }}
                sx={{ flexGrow: 1, border: '1px solid #ccc', borderRadius: 1, px: 1, py: 0.5, backgroundColor: "#D3D3D3" }}
              />
            </Box>
          </Box>
        </Card>
      ))}
    </Box>
  );
};

export default Post;
