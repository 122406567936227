// src/pages/payment.js
import React, { useEffect, useState, useContext } from 'react';
import axios from 'axios';
import './PaymentPage.css';
import { UserContext } from '../Context/UserContext';

const PaymentPage = () => {
  const { user } = useContext(UserContext);
  const [selectedPlan, setSelectedPlan] = useState('basic');
  const [loadingPlans, setLoadingPlans] = useState({});
  const [error, setError] = useState(null);

  
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  const plans = {
    monthly: {
      amount: 60,
      name: 'Monthly Plan',
      price: '₹60/month',
      // savings: 'Save 10%',
      features: [
        'Access to all content',
        'HD streaming',
        'Watch on 1 device at a time',
        'Exclusive content access'

      ]
    },
    quarterly: {
      amount: 170,
      name: 'Quarterly Plan',
      price: '₹170/3 months',
      savings: 'Save 10%',
      features: [
        'Access to all content',
        'HD streaming',
        'Watch on 2 devices (one mobile - one Laptop) at a time',
        'Exclusive content access'

      ]
    },
    semiannual: {
      amount: 320,
      name: 'Semi-Annual Plan',
      price: '₹320/6 months',
      savings: 'Save 11%',
      features: [
        'Access to all content',
        'Full HD streaming',
        'Watch on 2 devices (one mobile - one Laptop) at a time',
        'Priority customer support',
        'Exclusive content access'
      ]
    },
    annual: {
      amount: 600,
      name: 'Annual Plan',
      price: '₹600/year',
      savings: 'Save 17%',
      features: [
        'Access to all content',
        'Ultra HD streaming',
        'Watch on 2 devices (one mobile - one Laptop) at a time',
        'Priority customer support',
        'Exclusive content access'
      ]
    }
  };


  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://checkout.razorpay.com/v1/checkout.js';
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handlePayment = async (planKey) => {
    if (!user) {
      setError("User is not logged in");
      return;
    }

    setLoadingPlans((prev) => ({ ...prev, [planKey]: true }));
    setError(null);
    try {
      const { amount, name } = plans[planKey];

      const orderResponse = await axios.post(`${apiBaseURL}/api/payment/create-order`, {
        amount,
        currency: 'INR',
        receipt: `${user.id}_${planKey}`,
        plan: planKey,
      });

      const { order } = orderResponse.data;

      const options = {
        key: process.env.REACT_APP_RAZORPAY_KEY_ID,
        amount: order.amount,
        currency: order.currency,
        name: 'The Camera Tales',
        description: name,
        order_id: order.id,
        handler: async function (response) {
          const result = await axios.post(`${apiBaseURL}/api/payment/verify-payment`, {
            razorpay_order_id: response.razorpay_order_id,
            razorpay_payment_id: response.razorpay_payment_id,
            razorpay_signature: response.razorpay_signature,
            userId: user.id,
            plan: planKey,
          });

          if (result.data.success) {
            alert('Payment Successful');
          } else {
            alert('Payment Failed');
          }
        },
        prefill: {
          name: `${user.firstName} ${user.lastName}`,
          email: user.email,
          contact: user.contact || '9999999999', // Default contact if not available
        },
        theme: {
          color: '#F37254',
        },
      };

      const rzp = new window.Razorpay(options);
      rzp.open();
    } catch (error) {
      setError(error.message);
    } finally {
      setLoadingPlans((prev) => ({ ...prev, [planKey]: false }));
    }
  };

  return (
    <div className="payment-container">
      <h1 style={{ color: 'white', marginBottom: '2vh', marginTop: '30vh' }}>Subscription Plans</h1>
      <div className="subscription-plans">
        {Object.keys(plans).map((planKey) => (
          <div
            key={planKey}
            className={`plan-card ${selectedPlan === planKey ? 'selected' : ''}`}
            onClick={() => setSelectedPlan(planKey)}
          >
            <div className="plan-header">{plans[planKey].name}</div>

            <div className="plan-price">
              {plans[planKey].price}
              {plans[planKey].savings && <div className="plan-savings" style={{color:'green'}}>{plans[planKey].savings}</div>}
            </div>

            <ul className="plan-features">
              {plans[planKey].features.map((feature, index) => (
                <li key={index}>{feature}</li>
              ))}
            </ul>

            <button
              className="plan-select"
              onClick={() => handlePayment(planKey)}
              disabled={loadingPlans[planKey]}
            >
              {loadingPlans[planKey] ? 'Processing...' : 'Select Plan'}
            </button>
          </div>
        ))}
      </div>

      {error && <p className="error-message">{error}</p>}
    </div>
  );
};

export default PaymentPage;
