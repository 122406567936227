import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Container,
  Grid,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Typography,
  Box,
  Divider,
  Paper,
} from '@mui/material';
import SaveIcon from '@mui/icons-material/Save';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const ProfileUpdate = () => {
  const [userData, setUserData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    role: 'user',
    profilePicture: '',
    biography: '',
    birthDate: '',
    nationality: '',
    gender: '',
    imdbProfile: '',
    twitterHandle: '',
    instagramHandle: '',
    socialLinks: [{ type: '', link: '' }],
    contactInfo: {
      phone: '',
      address: {
        street: '',
        city: '',
        state: '',
        zip: '',
        country: ''
      }
    },
    knownFor: [''],
    awards: [{ name: '', year: '' }],
    website: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');

  
  const apiBaseURL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const userId = storedUser ? storedUser.id : null;

    if (userId) {
      axios.get(`${apiBaseURL}/api/users/${userId}`)
        .then(response => {
          setUserData(prevState => ({
            ...prevState,
            ...response.data
          }));
          setLoading(false);
        })
        .catch(error => {
          setError('Error fetching user data');
          setLoading(false);
        });
    } else {
      setError('User ID not found in local storage');
      setLoading(false);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleNestedInputChange = (e, parentKey, childKey) => {
    const { value } = e.target;
    setUserData(prevState => ({
      ...prevState,
      [parentKey]: {
        ...prevState[parentKey],
        [childKey]: value
      }
    }));
  };

  const handleArrayInputChange = (e, key, index, field) => {
    const { value } = e.target;
    setUserData(prevState => {
      const updatedArray = [...prevState[key]];
      if (field) {
        updatedArray[index] = {
          ...updatedArray[index],
          [field]: value
        };
      } else {
        updatedArray[index] = value;
      }
      return {
        ...prevState,
        [key]: updatedArray
      };
    });
  };

  const addToArray = (key) => {
    if (key === 'socialLinks') {
      setUserData(prevState => ({
        ...prevState,
        [key]: [...prevState[key], { type: '', link: '' }]
      }));
    } else if (key === 'awards') {
      setUserData(prevState => ({
        ...prevState,
        [key]: [...prevState[key], { name: '', year: '' }]
      }));
    } else {
      setUserData(prevState => ({
        ...prevState,
        [key]: [...prevState[key], '']
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    
    const storedUser = JSON.parse(localStorage.getItem('user'));
    const userId = storedUser ? storedUser.id : null;
  
    if (userId) {
      try {
        const response = await axios.put(`${apiBaseURL}/api/users/${userId}`, userData);
        alert('User updated successfully');
      } catch (error) {
        setError('Error updating user');
      }
    } else {
      setError('User ID not found in local storage');
    }
  };

  if (loading) return <p>Loading...</p>;
  if (error) return <p>{error}</p>;

  return (
    <Container maxWidth="md" sx={{ mt: 8 }}>
      <Paper elevation={3} sx={{ p: 4, borderRadius: 4, backgroundColor: '#1c1c1c' }}>
        <Typography variant="h4" align="center" gutterBottom sx={{ color: '#fff' }}>
          Update Profile
        </Typography>
        <Divider sx={{ mb: 3, backgroundColor: '#444' }} />
        <form onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="First Name"
                name="firstName"
                value={userData.firstName}
                onChange={handleInputChange}
                required
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Last Name"
                name="lastName"
                value={userData.lastName}
                onChange={handleInputChange}
                required
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Email"
                type="email"
                name="email"
                value={userData.email}
                onChange={handleInputChange}
                required
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Password"
                type="password"
                name="password"
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#333' }}>
                <InputLabel sx={{ color: '#aaa' }}>Role</InputLabel>
                <Select
                  name="role"
                  value={userData.role}
                  onChange={handleInputChange}
                  label="Role"
                  sx={{ color: '#fff' }}
                >
                  <MenuItem value="user">User</MenuItem>
                  <MenuItem value="admin">Admin</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Biography"
                multiline
                rows={4}
                name="biography"
                value={userData.biography}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Birth Date"
                type="date"
                name="birthDate"
                value={userData.birthDate}
                onChange={handleInputChange}
                InputLabelProps={{ shrink: true, style: { color: '#aaa' } }}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Nationality"
                name="nationality"
                value={userData.nationality}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <FormControl fullWidth variant="outlined" sx={{ backgroundColor: '#333' }}>
                <InputLabel sx={{ color: '#aaa' }}>Gender</InputLabel>
                <Select
                  name="gender"
                  value={userData.gender}
                  onChange={handleInputChange}
                  label="Gender"
                  sx={{ color: '#fff' }}
                >
                  <MenuItem value="">Select</MenuItem>
                  <MenuItem value="male">Male</MenuItem>
                  <MenuItem value="female">Female</MenuItem>
                  <MenuItem value="other">Other</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="IMDB Profile"
                name="imdbProfile"
                value={userData.imdbProfile}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Twitter Handle"
                name="twitterHandle"
                value={userData.twitterHandle}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                fullWidth
                label="Instagram Handle"
                name="instagramHandle"
                value={userData.instagramHandle}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>

            {/* Social Links */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                Social Links
              </Typography>
              {userData.socialLinks.map((link, index) => (
                <Box key={index} display="flex" gap={2} mb={2}>
                  <TextField
                    fullWidth
                    label="Type (e.g., Twitter, Facebook)"
                    value={link.type}
                    onChange={(e) => handleArrayInputChange(e, 'socialLinks', index, 'type')}
                    variant="outlined"
                    sx={{ backgroundColor: '#333', color: '#fff' }}
                    InputLabelProps={{ style: { color: '#aaa' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                  <TextField
                    fullWidth
                    label="Link URL"
                    value={link.link}
                    onChange={(e) => handleArrayInputChange(e, 'socialLinks', index, 'link')}
                    variant="outlined"
                    sx={{ backgroundColor: '#333', color: '#fff' }}
                    InputLabelProps={{ style: { color: '#aaa' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                </Box>
              ))}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => addToArray('socialLinks')}
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
              >
                Add Social Link
              </Button>
            </Grid>

            {/* Contact Information */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                Contact Information
              </Typography>
              <TextField
                fullWidth
                label="Phone"
                name="phone"
                value={userData.contactInfo.phone}
                onChange={(e) => handleNestedInputChange(e, 'contactInfo', 'phone')}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
                mb={2}
              />
              <Typography variant="subtitle1" gutterBottom sx={{ color: '#fff' }}>
                Address
              </Typography>
              <Box display="flex" gap={2} mb={2}>
                <TextField
                  fullWidth
                  label="Street"
                  value={userData.contactInfo.address.street}
                  onChange={(e) => handleNestedInputChange(e, 'contactInfo', 'street')}
                  variant="outlined"
                  sx={{ backgroundColor: '#333', color: '#fff' }}
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                <TextField
                  fullWidth
                  label="City"
                  value={userData.contactInfo.address.city}
                  onChange={(e) => handleNestedInputChange(e, 'contactInfo', 'city')}
                  variant="outlined"
                  sx={{ backgroundColor: '#333', color: '#fff' }}
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
              </Box>
              <Box display="flex" gap={2} mb={2}>
                <TextField
                  fullWidth
                  label="State"
                  value={userData.contactInfo.address.state}
                  onChange={(e) => handleNestedInputChange(e, 'contactInfo', 'state')}
                  variant="outlined"
                  sx={{ backgroundColor: '#333', color: '#fff' }}
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
                <TextField
                  fullWidth
                  label="Zip Code"
                  value={userData.contactInfo.address.zip}
                  onChange={(e) => handleNestedInputChange(e, 'contactInfo', 'zip')}
                  variant="outlined"
                  sx={{ backgroundColor: '#333', color: '#fff' }}
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: { color: '#fff' } }}
                />
              </Box>
              <TextField
                fullWidth
                label="Country"
                value={userData.contactInfo.address.country}
                onChange={(e) => handleNestedInputChange(e, 'contactInfo', 'country')}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>

            {/* Known For */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                Known For
              </Typography>
              {userData.knownFor.map((item, index) => (
                <TextField
                  fullWidth
                  key={index}
                  label={`Known For #${index + 1}`}
                  value={item}
                  onChange={(e) => handleArrayInputChange(e, 'knownFor', index)}
                  variant="outlined"
                  sx={{ backgroundColor: '#333', color: '#fff' }}
                  InputLabelProps={{ style: { color: '#aaa' } }}
                  InputProps={{ style: { color: '#fff' } }}
                  mb={2}
                />
              ))}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => addToArray('knownFor')}
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
              >
                Add Known For
              </Button>
            </Grid>

            {/* Awards */}
            <Grid item xs={12}>
              <Typography variant="h6" gutterBottom sx={{ color: '#fff' }}>
                Awards
              </Typography>
              {userData.awards.map((award, index) => (
                <Box key={index} display="flex" gap={2} mb={2}>
                  <TextField
                    fullWidth
                    label="Award Name"
                    value={award.name}
                    onChange={(e) => handleArrayInputChange(e, 'awards', index, 'name')}
                    variant="outlined"
                    sx={{ backgroundColor: '#333', color: '#fff' }}
                    InputLabelProps={{ style: { color: '#aaa' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                  <TextField
                    fullWidth
                    label="Year"
                    value={award.year}
                    onChange={(e) => handleArrayInputChange(e, 'awards', index, 'year')}
                    variant="outlined"
                    sx={{ backgroundColor: '#333', color: '#fff' }}
                    InputLabelProps={{ style: { color: '#aaa' } }}
                    InputProps={{ style: { color: '#fff' } }}
                  />
                </Box>
              ))}
              <Button
                startIcon={<AddCircleOutlineIcon />}
                onClick={() => addToArray('awards')}
                sx={{ mt: 2 }}
                variant="contained"
                color="primary"
              >
                Add Award
              </Button>
            </Grid>

            {/* Website */}
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Website"
                name="website"
                value={userData.website}
                onChange={handleInputChange}
                variant="outlined"
                sx={{ backgroundColor: '#333', color: '#fff' }}
                InputLabelProps={{ style: { color: '#aaa' } }}
                InputProps={{ style: { color: '#fff' } }}
              />
            </Grid>

            {/* Submit Button */}
            <Grid item xs={12} textAlign="center">
              <Button
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
                sx={{ mt: 4, backgroundColor: '#007bff' }}
              >
                Save Changes
              </Button>
            </Grid>
          </Grid>
        </form>
      </Paper>
    </Container>
  );
};

export default ProfileUpdate;
